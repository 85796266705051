import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Typography, useTheme } from '@mui/material';
import { RootState } from '@store/rootReducer';

import { OONavigationContext } from '../context/NavigationContext';
import { useFlowWrapper } from '../hooks/useFlowWrapper';
import { OOPageModel } from '../models/FormModel';
import { checkPageConditions } from '../wrappers/FlowWrapper';

export const ProgressLine: React.FC = () => {
  const { form, step: currentStep } = useSelector((state: RootState) => state.oneOnboarding);
  const theme = useTheme();
  const { t, i18n } = useTranslation(['stepper', 'hiring', 'approval']);
  const navigationContext = useContext(OONavigationContext);
  const flowWrapper = useFlowWrapper();
  const { themeAdditionalData } = useSelector((state: RootState) => state.oneOnboarding);
  const currentPageAndStep = useMemo(() => {
    return flowWrapper?.getCurrentStepAndFormFromNavigationPage(navigationContext.currentPage);
  }, [navigationContext, flowWrapper]);

  if (!flowWrapper) {
    return <></>;
  }
  const step = flowWrapper.getStep(currentStep);

  if (!step) {
    return <></>;
  }

  const pageForms = step.pages.filter((p) => !p.condition || checkPageConditions(p.condition));
  const currentStepIndex = pageForms.map((p) => p.name).indexOf(form);
  const jumpConditionIndex = pageForms.findIndex((page, i) => page?.jumpCondition && currentStepIndex <= i);
  const calculateNumOfPages = () => {
    if (currentStepIndex <= jumpConditionIndex) return jumpConditionIndex + 1;
    return pageForms.length;
  };
  const numOfPages = calculateNumOfPages();
  const stepProgress = (currentStepIndex + 1) / numOfPages;
  const percentage = stepProgress * 100 + '%';
  const pageForm: OOPageModel | undefined = step.pages[currentStepIndex];
  const displayName = pageForm?.displayName;
  const prefix = displayName ? displayName.split('.')[0].toLowerCase() : 'entry';
  const progressNumbers = `${currentStepIndex + 1 < 10 ? '0' : ''}${currentStepIndex + 1}/${
    numOfPages < 10 ? '0' : ''
  }${numOfPages}`;

  return (
    <Box
      pt={1}
      pb={2}
      style={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 100,
      }}
    >
      <Box my={1}>
        <p className="tag-ds  small-title" style={{ ...themeAdditionalData.smallTitle }}>
          {t('stepper:' + currentPageAndStep?.stepLabel)}
        </p>
      </Box>
      <div className="line-stepper">
        <div
          style={{
            backgroundColor: themeAdditionalData?.progressLineColor || theme.palette.common.adeccoRed,
            width: percentage,
          }}
          className="line-stepper__progress-line"
        />
        <div
          style={{
            backgroundColor: theme.palette.text.disabled,
          }}
          className="line-stepper__bg-line"
        />
      </div>
      <Box display="flex" justifyContent="space-between" my={1}>
        <Typography variant="subtitle2" color="textSecondary">
          {progressNumbers}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {i18n.exists(`${prefix}:${displayName}`)
            ? t(`${prefix}:${displayName}`)
            : i18n.exists(`stepper:${displayName}`)
            ? t(`stepper:${displayName}`)
            : ''}
        </Typography>
      </Box>
    </Box>
  );
};
