import React, { FC, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Drawer, DrawerBody, Tree, TreeViewElement } from '@adeccoux/tag-ds';
import { hasRejectedMandatoryDocument } from '@features/oneOnboarding/components/RoundVerticalStepper';
import { useT } from '@features/oneOnboarding/hooks/useT';
import { OOFeaturesEnum, OOSteps, OOStepsEnum, StepStatusEnum } from '@features/oneOnboarding/interfaces';
import { OOPageModel } from '@features/oneOnboarding/models/FormModel';
import { OOStepModel } from '@features/oneOnboarding/models/StepModel';
import { checkPageConditions, OOFlowWrapper } from '@features/oneOnboarding/wrappers/FlowWrapper';

import styles from './CandidateProfileSidebar.module.scss';

const StepIcons: Record<any, string> = {
  CANDIDATE_DETAILS: 'manage_accounts',
  DOCUMENT_UPLOAD: 'upload_file',
  HIRE_SIGNATURE: 'text_format',
  CANDIDATE_SIGN_DOCUMENTS: 'assignment',
  DOCUMENT_UPLOAD2: 'request_quote',
  HIRE: 'folder',
  REGISTRATION: 'app_registration',
};

interface CandidateProfileSidebarProps {
  onSelect: (step: OOStepModel, page: OOPageModel | undefined) => void;
  flowWrapper: OOFlowWrapper | undefined;
  steps: OOStepModel[];
  allSteps: OOStepModel[];
  currentStep: string;
}
export const signDocumentsSteps: Array<OOSteps> = [
  'CANDIDATE_SIGN_DOCUMENTS',
  'CANDIDATE_CONFIRMS_DOCUMENTS',
  'RECRUITER_SIGN_DOCUMENTS',
];

export const getIcon = (step: OOStepModel) => {
  if (step.status === StepStatusEnum.completed) {
    return 'check_circle';
  }
  if (StepIcons[step.name]) {
    return StepIcons[step.name];
  }
  return undefined;
};
export const getStepStatus = (step: OOStepModel, flowWrapper: OOFlowWrapper, currentStep: string): StepStatusEnum => {
  if (step.name === OOStepsEnum.candidateSignDocuments || step.name === OOStepsEnum.candidateConfirmsDocuments) {
    const steps = flowWrapper.steps.map((s) => s.name);
    const currentStepIndex = steps.indexOf(currentStep as OOSteps);
    const recruiterSignStepIndex = steps.indexOf(OOStepsEnum.recruiterSignDocuments);
    const candidateSignStepIndex = steps.indexOf(step.name);

    if (
      (recruiterSignStepIndex === -1 && currentStepIndex === candidateSignStepIndex) ||
      (currentStepIndex >= recruiterSignStepIndex &&
        recruiterSignStepIndex >= 0 &&
        currentStepIndex <= candidateSignStepIndex)
    ) {
      return StepStatusEnum.inProgress;
    }
  }
  return step.status || StepStatusEnum.notStarted;
};

export const CandidateProfileSidebar: FC<CandidateProfileSidebarProps> = ({
  onSelect,
  flowWrapper,
  steps,
  allSteps,
  currentStep,
}) => {
  const { t } = useT('stepper', 'recruiter', 'approval', 'documents', 'entry');

  const [selectedPage, setSelectedPage] = useState<{ step: OOStepModel | undefined; page: OOPageModel | undefined }>({
    step: undefined,
    page: undefined,
  });

  const onSelectLocalHandler = (step: OOStepModel, page: OOPageModel | undefined) => {
    setSelectedPage({ step, page });
    onSelect(step, page);
  };

  const matchStep = useMemo(() => {
    if (flowWrapper) {
      const steps = flowWrapper.getAllFeatureSteps(OOFeaturesEnum.matching);
      if (steps.length) {
        return steps[0];
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }, [flowWrapper]);

  const enableStepInteraction = (step: OOStepModel, allowStepByName?: string): boolean => {
    if (flowWrapper) {
      const stepStatus = getStepStatus(step, flowWrapper, currentStep);
      const allowStepByStatus = stepStatus === StepStatusEnum.completed || stepStatus === StepStatusEnum.inProgress;
      if (allowStepByName) return step.name === allowStepByName || allowStepByStatus;
      return allowStepByStatus;
    }
    return false;
  };

  const matchStepStatus = allSteps.find((x) => x.name === 'MATCH')?.status ?? StepStatusEnum.notStarted;
  const showMatching = [StepStatusEnum.inProgress, StepStatusEnum.completed].includes(matchStepStatus);

  const renderSteps = useMemo(() => steps.filter((x) => x.isVisibleRecruiter), [steps]);

  return (
    <div className={styles.candidateProfileSidebar}>
      <div className="tag-ds ">
        <Drawer className={clsx('sidepanel', styles.drawer)} open>
          <DrawerBody>
            <Tree>
              <>
                {renderSteps.map((step, index) => {
                  const pages = step.pages
                    .filter((x) => x.isVisibleRecruiter || x.isVisibleRecruiter === undefined)
                    .filter((x) => !x.condition || checkPageConditions(x.condition));
                  const errorStep = hasRejectedMandatoryDocument(step);

                  return pages.length > 0 ? (
                    <TreeViewElement
                      className={clsx(styles['tree-view-element'], {
                        [styles.success]: step.status === StepStatusEnum.completed,
                        [styles.inProgress]: step.status === StepStatusEnum.inProgress,
                      })}
                      key={index}
                      disabled={!enableStepInteraction(step, currentStep)}
                      arrow="right"
                      icon={getIcon(step)}
                      name={t(`stepper:${step.recruiterLabel}`)}
                      onClick={() => {
                        if (signDocumentsSteps.includes(step.name) && step.documentsForSignature.length > 0) {
                          onSelectLocalHandler(step, undefined);
                        } else {
                          pages.length === 1 && onSelectLocalHandler(step, pages[0]);
                        }
                      }}
                    >
                      {pages.length > 1 &&
                        pages.map((page, index) => {
                          return (
                            <TreeViewElement
                              className={clsx(styles['tree-view-element'], {
                                [styles.inProgress]:
                                  selectedPage.page?.name === page?.name && step.name === selectedPage.step?.name,
                              })}
                              name={t(`${page.displayName ? page.displayName : page.label}`)}
                              disabled={!enableStepInteraction(step)}
                              key={index}
                              onClick={() => {
                                onSelectLocalHandler(step, page);
                              }}
                            />
                          );
                        })}
                    </TreeViewElement>
                  ) : (
                    <TreeViewElement
                      arrow="right"
                      className={clsx(styles['tree-view-element'], {
                        [styles.success]: step.status === StepStatusEnum.completed,
                        [styles.inProgress]: step.status === StepStatusEnum.inProgress,
                        [styles.error]: errorStep,
                      })}
                      disabled={!enableStepInteraction(step)}
                      icon={!errorStep ? getIcon(step) : 'error'}
                      name={t(`stepper:${step.recruiterLabel}`)}
                      key={index}
                      onClick={() => {
                        if (
                          (signDocumentsSteps.includes(step.name) && step.documentsForSignature.length > 0) ||
                          step.name === OOStepsEnum.requestConfirmation ||
                          step.name === OOStepsEnum.import
                        ) {
                          onSelectLocalHandler(step, undefined);
                        } else {
                          step.documentations.length && onSelectLocalHandler(step, undefined);
                        }
                      }}
                    />
                  );
                })}

                {matchStep && showMatching && (
                  <TreeViewElement
                    arrow="right"
                    className={clsx(styles.matchStep, styles['tree-view-element'], {
                      [styles.success]: matchStep.status === StepStatusEnum.completed,
                      [styles.inProgress]: matchStep.status === StepStatusEnum.inProgress,
                    })}
                    disabled={!enableStepInteraction(matchStep)}
                    icon={getIcon(matchStep)}
                    name={t(`stepper:${matchStep.recruiterLabel}`)}
                    onClick={() => onSelectLocalHandler(matchStep, matchStep.pages[0])}
                  />
                )}
              </>
            </Tree>
          </DrawerBody>
        </Drawer>
      </div>
    </div>
  );
};
