import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useInjection } from '@context/inversify-context-provider';
import { ReadOnlyDocumentInfo } from '@features/oneOnboarding/components/ReadOnlyDocumentInfo';
import { OODocumentContext } from '@features/oneOnboarding/context/DocumentContext';
import { OOFormControl } from '@features/oneOnboarding/controls/FormControl';
import { controlModelToValidationObject, parseValidationErrors } from '@features/oneOnboarding/helpers';
import { OOStepsEnum } from '@features/oneOnboarding/interfaces';
import { OOControlModel } from '@features/oneOnboarding/models/ControlModel';
import { OOFlowWrapper } from '@features/oneOnboarding/wrappers/FlowWrapper';
import { OnboardingDocumentStatus } from '@helpers/OnboardingSteps';
import { mutateUserFlow, useDownloadDocumentWithFullResponse } from '@hooks/apiHooks';
import { useDecodedParams } from '@hooks/useDecodedParams';
import { OnboardingStatus } from '@models/onboarding.model';
import { Box, CircularProgress, Grid } from '@mui/material';
import { store } from '@store/index';
import { RootState } from '@store/rootReducer';
import { useQueryClient } from '@tanstack/react-query';

import { AamBackendApi } from '../../../../../../libs/aamBackendApi';
import PreviewActions from './components/PreviewActions';

import styles from './DocumentPages.module.scss';

interface PreviewDocumentProps {
  changeToNextDocument: () => void;
  hasValidationStep: boolean;
}
const PreviewDocument: React.FC<PreviewDocumentProps> = ({ changeToNextDocument, hasValidationStep }) => {
  const [approvalInProgress, setApprovalInProgress] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<any | null>(null);
  const [selectedDocumentPartLabel, setSelectedDocumentPartLabel] = useState<string>('');
  const [imageControls, setImageControls] = useState({
    zoom: 0,
    rotate: 0,
    x: 0,
    y: 0,
  });
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);

  const { clientOrganizationId, userId, applicationId, configurationId } = useDecodedParams();
  const { t } = useTranslation(['documents', 'candidate_recruiter']);
  const divRef: any = useRef(null);
  const { tenantId } = useSelector((state: RootState) => state.tenant);
  const aamBackendApi = useInjection(AamBackendApi);
  const { step, document, setCommand, setDocument, documentationModel } = useContext(OODocumentContext);
  const queryClient = useQueryClient();

  const stepName = step?.name;
  const selectedDocument = document;

  const { selectedOnboarding } = useSelector((state: RootState) => state.dashboard)!;

  const documentImages = useDownloadDocumentWithFullResponse(tenantId, document?.parts || []);
  const images = useMemo(() => documentImages.filter((i) => i.data).map((image) => image.data) || [], [documentImages]);

  const flowData = store.getState().oneOnboarding.flow;

  const flowWrapper = useMemo(() => {
    if (!flowData) return null;
    return OOFlowWrapper.create(flowData);
  }, [flowData]);

  useEffect(() => {
    setSelectedImage(null);
  }, [selectedDocument]);

  useEffect(() => {
    if (!selectedDocument || !selectedImage) return;
    const part = selectedDocument.parts?.find((item) => item.name === selectedImage.name);
    setSelectedDocumentPartLabel(part?.label ?? selectedDocument.label);
  }, [selectedDocument, selectedImage]);

  useEffect(() => {
    if (!images[0] || selectedImage) return;

    setSelectedImage(images[0]);
  }, [images, selectedImage]);

  // Used to center image on load
  useEffect(() => {
    if (divRef.current && selectedImage) {
      const { width, height } = divRef.current.getBoundingClientRect();

      setImageControls((state: any) => ({ ...state, x: width / 2, y: height / 2 }));
    }
  }, [selectedImage, images.length]);

  const currentActiveImageIndex = useMemo(() => {
    return images.findIndex((i) => i?.name === selectedImage?.name) + 1;
  }, [images, selectedImage]);

  const validationSchema = useMemo(() => {
    if (!selectedDocument) return Yup.object({});
    return Yup.object({
      ...controlModelToValidationObject(selectedDocument?.controls, t, false, flowWrapper, 'documents'),
    });
  }, [selectedDocument, t, flowWrapper]);

  const initialValues = useMemo(() => {
    return selectedDocument?.controls
      ? selectedDocument.controls.reduce((acc: any, c: any) => {
          acc[c.name] = c.value;
          return acc;
        }, {} as any)
      : [];
  }, [selectedDocument?.controls]);

  const { mutateAsync } = mutateUserFlow(
    clientOrganizationId,
    configurationId,
    userId,
    applicationId,
    queryClient,
    () => setStatusEx(OnboardingDocumentStatus.VERIFIED),
  );

  if (!selectedDocument) {
    setCommand(undefined);
    setDocument(undefined);
    return <div>No document selected</div>;
  }

  const setStatusEx = async (status: OnboardingDocumentStatus) => {
    try {
      setApprovalInProgress(true);
      if (status === OnboardingDocumentStatus.REJECTED) {
        await aamBackendApi.rejectDocument(
          clientOrganizationId,
          userId,
          selectedDocument.userDocument.id,
          {
            additionalData: { status },
          },
          applicationId,
          configurationId,
        );
      } else {
        await aamBackendApi.approveDocument(
          clientOrganizationId,
          userId,
          selectedDocument.userDocument.id,
          {
            additionalData: { status },
          },
          applicationId,
          configurationId,
        );
      }
    } finally {
      setApprovalInProgress(false);
      setImageControls({ zoom: 0, rotate: 0, x: 0, y: 0 });
    }

    if (status !== OnboardingDocumentStatus.MISSING) {
      queryClient.invalidateQueries(['getUserFlow', clientOrganizationId, configurationId, userId, applicationId]);
      queryClient.invalidateQueries([
        'getUserOOFlowDocumentUpload',
        clientOrganizationId,
        configurationId,
        userId,
        applicationId,
      ]);

      changeToNextDocument();
    } else {
      setCommand(undefined);
      setDocument(undefined);
    }
  };

  const refetchList = async () => {
    queryClient.invalidateQueries(['getUserFlow', clientOrganizationId, configurationId, userId, applicationId]);
    queryClient.invalidateQueries([
      'getUserOOFlowDocumentUpload',
      clientOrganizationId,
      configurationId,
      userId,
      applicationId,
    ]);
  };

  const closeDrawerAndRefresh = async () => {
    setCommand(undefined);
    setDocument(undefined);
    refetchList();
  };

  const documentStatus = selectedDocument.userDocument?.additionalData?.status as OnboardingDocumentStatus;
  const isReadOnly = document.userDocument?.isReadOnly;

  const handleImageControls = (property: 'zoom' | 'rotate', value: number) => {
    setImageControls((state: any) => ({ ...state, [property]: state[property] += value }));
  };

  const handleMouseDown = (e: any) => {
    setOffsetX(e.clientX - imageControls.x);
    setOffsetY(e.clientY - imageControls.y);
  };

  const handleMouseMove = (e: any) => {
    const x = e.clientX - offsetX;
    const y = e.clientY - offsetY;

    setImageControls((state: any) => ({ ...state, x, y }));
  };

  const zoomValue = () => {
    const steps: any = {
      '-7': 25,
      '-6': 33,
      '-5': 50,
      '-4': 67,
      '-3': 75,
      '-2': 80,
      '-1': 90,
      '0': 100,
      '1': 110,
      '2': 125,
      '3': 150,
      '4': 175,
      '5': 200,
      '6': 250,
      '7': 300,
      '8': 400,
      '9': 500,
    };

    if (imageControls.zoom > 9) {
      setImageControls((state) => ({ ...state, zoom: 9 }));
    }

    if (imageControls.zoom < -7) {
      setImageControls((state) => ({ ...state, zoom: -7 }));
    }

    return steps[imageControls.zoom.toString()] / 100;
  };

  const onSubmit = async (values: any, actions: any) => {
    try {
      const files: any = {};
      const data: any = {};
      if (selectedDocument.controls.length > 0) {
        for (const [name, value] of Object.entries(values)) {
          data[name] = value;
        }
        for (const part of selectedDocument.parts) {
          files[part.name] = images.find((i) => i?.name === part.name)?.file;
        }
        OOFlowWrapper.prepareValues(selectedDocument.controls, data);
        const sendData = {
          currentStep: documentationModel?.uploadEarly ? OOStepsEnum.documentUploadTwo : stepName,
          currentPage: selectedDocument.name,
          data: data,
        };
        await mutateAsync({ data: sendData, files });
      } else {
        setStatusEx(OnboardingDocumentStatus.VERIFIED);
      }
    } catch (err) {
      const parsed = parseValidationErrors(t, err);
      if (parsed.errors) {
        actions.setErrors(parsed.errors);
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <Formik
          validateOnMount={true}
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          validateOnChange={true}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, isValid, isSubmitting }) => (
            <form onSubmit={handleSubmit} style={{ flexGrow: 1 }}>
              {isReadOnly && (
                <ReadOnlyDocumentInfo
                  document={document}
                  setDocument={setDocument}
                  isRecruiter={true}
                  closeDrawer={closeDrawerAndRefresh}
                />
              )}
              {!isReadOnly && (
                <Grid container spacing={3}>
                  {selectedDocument.controls
                    .filter((c: OOControlModel) => c.isVisibleRecruiter)
                    .sort((a, b) => {
                      if (a.sequence && b.sequence) {
                        return a.sequence - b.sequence;
                      }
                      return 0;
                    })
                    .map((control: OOControlModel) => (
                      <Grid key={control.name} item xs={12}>
                        <OOFormControl control={control} />
                      </Grid>
                    ))}
                </Grid>
              )}
              <>
                {approvalInProgress || isSubmitting ? (
                  <Box style={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Box className="documents-and-data-upload-approve-modal__action-container">
                    <div className="tag-ds " style={{ width: '100%' }}>
                      {documentStatus === OnboardingDocumentStatus.REJECTED && hasValidationStep && (
                        <>
                          <Box mb={2}>
                            <div
                              className="tag-ds status-tag_error"
                              style={{ height: 40, borderRadius: 8, width: '100%' }}
                            >
                              {t('documents:statuses.status_rejected')}
                              <div className="material-icons" style={{ marginLeft: 10, color: '#E87A71' }}>
                                do_not_disturb_alt
                              </div>
                            </div>
                          </Box>
                          <button
                            disabled={selectedOnboarding?.status === OnboardingStatus.hired}
                            onClick={() =>
                              setStatusEx(
                                isReadOnly ? OnboardingDocumentStatus.VALIDATING : OnboardingDocumentStatus.REVIEW,
                              )
                            }
                            style={{ width: '100%' }}
                          >
                            {t('candidate_recruiter:GENERAL.GENERIC.undo')}
                          </button>
                        </>
                      )}
                      {documentStatus === OnboardingDocumentStatus.VERIFIED && hasValidationStep && (
                        <>
                          <Box mb={2}>
                            <div
                              className="tag-ds status-tag_success "
                              style={{ height: 40, borderRadius: 8, width: '100%' }}
                            >
                              {t('documents:statuses.status_approved')}
                              <div className="material-icons" style={{ marginLeft: 10, color: '#73D85A' }}>
                                check_circle
                              </div>
                            </div>
                          </Box>
                          <button
                            disabled={
                              selectedOnboarding?.status === OnboardingStatus.hired ||
                              selectedOnboarding?.status === OnboardingStatus.readyForHire
                            }
                            onClick={() =>
                              setStatusEx(
                                isReadOnly ? OnboardingDocumentStatus.VALIDATING : OnboardingDocumentStatus.REVIEW,
                              )
                            }
                            style={{ width: '100%' }}
                          >
                            {t('candidate_recruiter:GENERAL.GENERIC.undo')}
                          </button>
                        </>
                      )}
                      {[OnboardingDocumentStatus.REVIEW, OnboardingDocumentStatus.VALIDATING].includes(
                        documentStatus,
                      ) &&
                        hasValidationStep && (
                          <>
                            {documentStatus !== OnboardingDocumentStatus.VALIDATING && (
                              <button
                                disabled={!isValid || approvalInProgress || isSubmitting}
                                onClick={handleSubmit as any}
                                style={{ width: '100%', marginBottom: 16 }}
                              >
                                {t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.approveDocument')}
                              </button>
                            )}
                            <button
                              disabled={isSubmitting}
                              onClick={() => setStatusEx(OnboardingDocumentStatus.REJECTED)}
                              style={{ width: '100%' }}
                              className="button-secondary"
                            >
                              {t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.rejectDocument')}
                            </button>
                          </>
                        )}
                    </div>
                  </Box>
                )}
              </>
            </form>
          )}
        </Formik>
      </div>
      {images.length > 1 && (
        <div className={styles.selection}>
          <div>
            {images.map((image: any, index: number) => (
              <React.Fragment key={index}>
                {image.isImage ? (
                  <img
                    className={styles.selection__item}
                    src={image.imageUrl}
                    alt="Document"
                    onClick={() => setSelectedImage(image)}
                    style={{
                      borderColor: image.name === selectedImage?.name ? ' #33CDFF' : ' transparent',
                    }}
                  />
                ) : (
                  <div
                    className={styles.selection__item}
                    style={{
                      borderColor: image.name === selectedImage?.name ? ' #33CDFF' : ' transparent',
                      position: 'relative',
                      padding: '16px 12px',
                      cursor: 'pointer',
                    }}
                    onClick={() => setSelectedImage(image)}
                  >
                    <span className="tag-ds small">{`${t('documents:actions.page')} ${index + 1}`}</span>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
      <div className={styles.previewContainer}>
        <div className={styles.preview} ref={divRef}>
          {!selectedImage && <CircularProgress />}
          {selectedImage && selectedImage.isImage && (
            <img
              src={selectedImage.imageUrl}
              alt="Dialog"
              width="640px"
              height="auto"
              style={{
                position: 'absolute',
                left: `${imageControls.x}px`,
                top: `${imageControls.y}px`,
                transform: `translate(-50%, -50%) scale(${zoomValue()}) rotate(${imageControls.rotate}deg)`,
                cursor: 'grab',
              }}
              onDragStart={handleMouseDown}
              onDragEnd={handleMouseMove}
              draggable={true}
            />
          )}
          {selectedImage && !selectedImage.isImage && (
            <iframe title="Document" style={{ width: '100%', height: '100%' }} src={selectedImage.imageUrl} />
          )}
        </div>
        {selectedImage && selectedImage.isImage && (
          <PreviewActions
            imageControls={imageControls}
            currentActiveImageIndex={currentActiveImageIndex}
            imagesLength={images.length}
            selectedImageName={t(selectedDocumentPartLabel)}
            handleImageControls={handleImageControls}
            zoomValue={zoomValue}
          />
        )}
      </div>
    </div>
  );
};

export default PreviewDocument;
