import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ViewOnlyContext } from '@context/view-only-provider';
import DocumentForSignatureDialog from '@features/selfService/components/DocumentForSignatureDialog/DocumentForSignatureDialog';
import { SelfServiceItemType } from '@models/selfService.model';
import { RootState } from '@store/rootReducer';
import {
  findDocumentInConfiguration,
  handleDeleteStep,
  updateConfigurationEntity,
} from '@store/slices/selfService.slice';

import { PathHelper } from '../../../../helpers/pathHelper';
import { translationHelper } from '../../../../helpers/translationHelper';
import { DocumentToSign, StepDocumentToSign } from '../../../../models/configurationModels';
import AddItemFromLibrary from '../other/AddItemFromLibrary/AddItemFromLibrary';
import DocumentToSignListItem from './DocumentToSignListItem';
import { BlankDocumentForSignTemplate } from './templates';

import styles from '../../ConfigurationSetup.module.scss';

interface Props {
  setDocumentToSignDetails: Dispatch<SetStateAction<DocumentToSign | undefined>>;
  documentToSignDetails: DocumentToSign;
  setLibraryItem: ({ name, id }: { name: string; id: string }) => void;
}

const DocumentToSignList: React.FC<Props> = ({ setDocumentToSignDetails, documentToSignDetails, setLibraryItem }) => {
  const [currentStepAndPath, setCurrentStepAndPath] = useState<
    { step: StepDocumentToSign; path: string[] } | undefined
  >(undefined);
  const [deleteWarningVisible, setDeleteWarningVisible] = useState(false);

  const { t, i18n } = useTranslation(['hiring']);
  const { isViewOnly } = useContext(ViewOnlyContext);

  const { selectedConfiguration, selectedFeature } = useSelector((store: RootState) => store.selfService);
  const dispatch = useDispatch();

  useEffect(() => {
    const pathHelper = new PathHelper(selectedConfiguration).signingFeature().signingStep();

    const path = pathHelper.getPath();
    const step = pathHelper.getObject();

    setCurrentStepAndPath({ step, path });
  }, [selectedConfiguration]);

  if (!currentStepAndPath) {
    return <div>The signing step could not be found in the configuration</div>;
  }

  const getTranslation = (key?: string) => {
    if (!key) return '';

    return translationHelper(key, i18n, t);
  };

  const updateStepDocumentList = (
    newDocumentList: DocumentToSign[],
    action: 'delete' | 'change' | 'add',
    change: any,
  ) => {
    dispatch(
      updateConfigurationEntity({
        value: { ...currentStepAndPath.step, documentsForSignature: newDocumentList },
        pathSegments: currentStepAndPath.path,
        modification: {
          type: action,
          property: change,
          typeOfChange: action === 'change' ? ['sequence'] : [],
        },
      }),
    );

    setCurrentStepAndPath((prevState) => ({
      ...currentStepAndPath!,
      step: {
        ...prevState!.step,
        documentsForSignature: newDocumentList,
      },
    }));
  };

  const handleDocumentForSignatureDelete = (documentName: string) => {
    const step = findDocumentInConfiguration(
      { featureName: selectedFeature?.featureName, stepName: selectedFeature?.stepName },
      selectedConfiguration,
    );

    if (!step) return;

    const newDocuments = step.documentsForSignature
      .filter((x: any) => x.name !== documentName)
      .map((y: any, i: number) => ({ ...y, sequence: i * 10 }));

    updateStepDocumentList(
      newDocuments,
      'delete',
      step.documentsForSignature.find((x: any) => x.name === documentName),
    );
  };

  const onDraggableDocumentDrop = (e: any, targetField: string) => {
    if (currentStepAndPath) {
      let docs = [...currentStepAndPath.step.documentsForSignature];
      const currentDocIndex = docs.findIndex((d) => d.name === e.dataTransfer.getData('name'));
      const currentDoc = docs[currentDocIndex];
      docs.splice(currentDocIndex, 1);
      const newIndex = docs.findIndex((d) => d.name === targetField);
      docs.splice(newIndex, 0, currentDoc);
      docs = docs.map((d, i) => ({ ...d, sequence: (i + 1) * 10 }));
      updateStepDocumentList(
        docs,
        'change',
        docs.find((d) => d.name === e.dataTransfer.getData('name')),
      );
    }
  };

  const createNewDocument = () => {
    setDocumentToSignDetails(BlankDocumentForSignTemplate as any);
  };

  const step = findDocumentInConfiguration(
    { featureName: selectedFeature?.featureName, stepName: selectedFeature?.stepName },
    selectedConfiguration,
  );

  return (
    <div className="tag-ds">
      <h4 className={`subtitle ${styles.pageName}`}>
        {getTranslation(currentStepAndPath.step.label)}
        <span className="material-icons" style={{ marginLeft: 8 }}>
          help_outline
        </span>
      </h4>
      {step.documentsForSignature
        .slice()
        .sort((a: any, b: any) => a.sequence - b.sequence)
        .map((doc: DocumentToSign, i: number) => {
          return (
            <DocumentToSignListItem
              key={doc.name}
              document={doc}
              setDocumentToSignDetails={() => setDocumentToSignDetails(doc)}
              isActive={documentToSignDetails?.name === doc.name}
              handleDelete={() => {
                const lastDocument = step.documentsForSignature.length === 1;
                if (lastDocument) {
                  setDeleteWarningVisible(true);
                  return;
                }
                handleDocumentForSignatureDelete(doc.name);
              }}
              onDragDrop={onDraggableDocumentDrop}
            />
          );
        })}
      {!isViewOnly && (
        <AddItemFromLibrary
          type={SelfServiceItemType.DOCUMENT_FOR_SIGNATURE}
          addItemFromLibrary={(item: any, name: string, id: string) => {
            setDocumentToSignDetails(item);
            setLibraryItem({ name, id });
          }}
          createNewControl={createNewDocument}
        />
      )}
      <DocumentForSignatureDialog
        isVisible={deleteWarningVisible}
        onCancel={() => setDeleteWarningVisible(false)}
        onSave={() => dispatch(handleDeleteStep({ stepName: step.name }))}
      />
    </div>
  );
};

export default DocumentToSignList;
