import React, { FC, useMemo, useRef, useState } from 'react';
import { Formik } from 'formik';
import { OOFormControl } from '@features/oneOnboarding/controls/FormControl';
import {
  editableCondition,
  mandatoryCondition,
  renderCondition,
} from '@features/oneOnboarding/hooks/useRenderCondition';
import { useT } from '@features/oneOnboarding/hooks/useT';
import { OOControlModel } from '@features/oneOnboarding/models/ControlModel';
import { OOPageModel } from '@features/oneOnboarding/models/FormModel';
import { OOStepModel } from '@features/oneOnboarding/models/StepModel';
import { OOFlowWrapper } from '@features/oneOnboarding/wrappers/FlowWrapper';
import { mutateUserFlow } from '@hooks/apiHooks';
import { useDecodedParams } from '@hooks/useDecodedParams';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Button } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import Notification, { useNotification } from '../../../../../components/admin/Notification/Notification';
import useBackendTranslations from '../../../../../hooks/useBackendTranslations';

import styles from './GeneralPageComponent.module.scss';

export interface GeneraPageComponentProps {
  page: OOPageModel;
  step: OOStepModel | undefined;
  flowWrapper: OOFlowWrapper;
}
export const GeneralPageComponent: FC<GeneraPageComponentProps> = ({ page, flowWrapper, step }) => {
  const { t, i18n } = useT('candidate_recruiter', 'entry');

  const { isActive, notificationType, notificationDetails, triggerNotification, closeNotification } = useNotification();

  const { clientOrganizationId, userId, applicationId, configurationId } = useDecodedParams();
  const queryClient = useQueryClient();

  const { mutateAsync } = mutateUserFlow(clientOrganizationId, configurationId, userId, applicationId, queryClient);

  const [edit, setEdit] = useState(true);
  const [saveEnabled, setSaveEnabled] = useState(true);

  const [validSchema, setValidSchema] = useState<any>(page.getValidationSchema(flowWrapper, t));
  const formikRef = useRef<any>();

  const onFormChange = () => {
    setValidSchema(page.getValidationSchema(flowWrapper, t, formikRef.current.values));
  };

  const isSaveEnabled = () => {
    const values = formikRef?.current?.values;
    if (!values) return;
    const controls = page.controls
      .filter((c) => c.isVisibleRecruiter && renderCondition(c, flowWrapper, values))
      .map((control: OOControlModel) => {
        control.isMandatory = mandatoryCondition(control, flowWrapper, values) ?? control.isMandatory;
        flowWrapper.getDisabledFieldForDE(control, flowWrapper);
        return control;
      });

    const isEditable = controls.some((control) => {
      const editableResult =
        flowWrapper?.isControlEditable(control.isEditableCandidate, control.isEditableRecruiter) ?? true;
      const editableConditionResult = editableCondition(control, flowWrapper, values);
      return editableResult || editableConditionResult;
    });

    if (!isEditable) return setSaveEnabled(false);
    setSaveEnabled(true);
  };

  useMemo(() => {
    isSaveEnabled();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikRef?.current?.values, page?.controls]);

  useBackendTranslations(i18n);

  const getPrefix = (label: string) => {
    let prefix = label?.split('.')?.[0].toLowerCase() || '';
    const prefixAlias: any = {
      documents2: 'documents',
      documents3: 'documents',
    };
    if (prefixAlias[prefix]) {
      prefix = prefixAlias[prefix];
    }
    return prefix;
  };

  return (
    <Formik
      enableReinitialize={true}
      validateOnMount={true}
      innerRef={formikRef}
      initialValues={page.getInitialValues(flowWrapper)}
      validationSchema={validSchema}
      onSubmit={async (values, actions) => {
        if (!saveEnabled) return;
        actions.setSubmitting(true);
        OOFlowWrapper.prepareValues(page.controls, values);
        const data = {
          data: flowWrapper?.generateArrayValues(values),
          currentStep: step?.name,
          currentPage: page.name,
        };
        try {
          await mutateAsync({ data });
          triggerNotification('success', { header: t('candidate_recruiter:GENERAL.GENERIC.successfullSaveMessage') });
        } catch (err) {
          const errorMessage = err.response.data.error.data.message;
          if (errorMessage) {
            const errors = JSON.parse(errorMessage);

            if (Object.keys(errors).length > 0) {
              const controlStatus: Record<string, any> = {};
              Object.keys(errors).forEach((x) => {
                const prefix = getPrefix(errors[x].message);
                controlStatus[x] = t(`${prefix}:${errors[x].message}`) || '';
              });
              actions.setErrors(controlStatus);
            }
          }
        } finally {
          actions.setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} onChange={onFormChange} onClick={onFormChange} className={styles.form}>
          <>
            <div className={styles.submit}>
              {edit ? (
                <Button
                  endIcon={<SaveIcon />}
                  variant="text"
                  fullWidth={false}
                  type="submit"
                  onClick={() => handleSubmit}
                  disabled={!saveEnabled}
                >
                  {t('candidate_recruiter:GENERAL.GENERIC.save')}
                </Button>
              ) : (
                <Button
                  endIcon={<EditIcon />}
                  variant="text"
                  fullWidth={false}
                  onClick={(event) => {
                    setEdit(true);
                    event.preventDefault();
                  }}
                >
                  {t('candidate_recruiter:GENERAL.GENERIC.edit')}
                </Button>
              )}
            </div>
            {page.controls
              .filter((c) => c.isVisibleRecruiter && renderCondition(c, flowWrapper, values))
              .map((control: OOControlModel) => {
                control.isMandatory = mandatoryCondition(control, flowWrapper, values) ?? control.isMandatory;
                flowWrapper.getDisabledFieldForDE(control, flowWrapper);
                return control;
              })
              .map((control: OOControlModel, index) => (
                <OOFormControl
                  control={control}
                  key={control.name + index + step?.name}
                  otherFormControls={page.controls}
                  filterSubControls={(c) => c.isVisibleRecruiter}
                />
              ))}
          </>
          <Notification
            type={notificationType}
            details={notificationDetails}
            isActive={isActive}
            hide={closeNotification}
          />
        </form>
      )}
    </Formik>
  );
};
