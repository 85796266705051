import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useInjection } from '@context/inversify-context-provider';
import { mutateSignDocument } from '@hooks/apiHooks';
import { useTenant } from '@hooks/useComponentMapping';
import { useDecodedParams } from '@hooks/useDecodedParams';

import { AamBackendApi } from '../../../../../../libs/aamBackendApi';
import AdminHeader from '../../../../components/AdminHeader/AdminHeader';

import styles from './ViewSignDocument.module.scss';

export interface ViewSignDocumentProps {
  onClose: () => void;
  selectedDoc: any;
}

export const ViewSignDocument: FC<ViewSignDocumentProps> = ({ onClose, selectedDoc }) => {
  const [doc, setDoc] = useState(null);
  const [documentPdf, setDocumentPdf] = useState('');
  const [isDocumentSigned, setIsDocumentSigned] = useState(false);
  const [isDocumentSignedByRecruiter, setIsDocumentSignedByRecruiter] = useState(false);

  const { t, i18n } = useTranslation(['recruiter', 'hiring', 'candidate_recruiter']);

  const { clientOrganizationId, userId, applicationId, configurationId } = useDecodedParams();
  const aamBackendApi = useInjection(AamBackendApi);
  const tenantId = useTenant();
  const { search } = useLocation();
  const filterValue = new URLSearchParams(search).get('filter');

  const downloadDocument = useCallback(
    async (item: any) => {
      if (selectedDoc) {
        const userDocuments = [...(item.userDocuments || [])].sort(
          (a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
        );
        let document;

        if (userId && userDocuments && userDocuments.length > 0) {
          setDoc({ ...selectedDoc, id: userDocuments[userDocuments.length - 1].id });
          document = await aamBackendApi.downloadDocument(tenantId, userId, userDocuments[userDocuments.length - 1].id);
        } else {
          setDoc(selectedDoc);
          document = await aamBackendApi.downloadOODocumentForSignature(item.name);
        }

        const pdf = URL.createObjectURL(
          new Blob([document], {
            type: 'application/pdf',
          }),
        );

        setDocumentPdf(pdf);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [aamBackendApi, userId, tenantId, selectedDoc],
  );

  useEffect(() => {
    if (selectedDoc) {
      let isDocumentSignedByRecruiter = false;
      let isDocumentSignedByCandidate = false;
      const userDocuments = [...(selectedDoc.userDocuments || [])].sort(
        (a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      );
      const document = userDocuments[userDocuments.length - 1];
      if (document) {
        if (document.type === selectedDoc.name && document.additionalData.signedByRecruiter === true) {
          isDocumentSignedByRecruiter = true;
        }
        const someDocumentSignedByCandidate = selectedDoc.userDocuments?.some(
          (d: any) => d.type === selectedDoc.name && d.additionalData.signed === true,
        );
        if (
          someDocumentSignedByCandidate &&
          document.type === selectedDoc.name &&
          document.additionalData.signed === true
        ) {
          isDocumentSignedByCandidate = true;
          isDocumentSignedByRecruiter = true;
        }
        setIsDocumentSigned(isDocumentSignedByCandidate);
        setIsDocumentSignedByRecruiter(isDocumentSignedByRecruiter);
        downloadDocument(selectedDoc);
      }
    }
  }, [selectedDoc, downloadDocument]);

  const { mutate: signDocument, isLoading, isSuccess, isError } = mutateSignDocument(
    clientOrganizationId,
    configurationId,
    userId,
    applicationId,
    i18n.language,
  );

  return (
    <div className={styles.pageContainer}>
      {selectedDoc && (
        <>
          <AdminHeader
            onBack={() => {
              onClose();
            }}
            pageName={t(`${selectedDoc?.label.split('.')[0].toLowerCase() || 'recruiter'}:${selectedDoc.label}`)}
            customStyle={{ paddingLeft: 32 }}
          />
          <div className={styles.contentContainer}>
            <div className={styles.preview}>
              {documentPdf && (
                <object
                  data={documentPdf}
                  type="application/pdf"
                  width="100%"
                  style={{ height: '100%' }}
                  aria-label="Document PDF"
                />
              )}
            </div>
            {(selectedDoc.isRecruiterSignatureRequired || selectedDoc.isCandidateSignatureRequired) && (
              <div className={styles.details}>
                {selectedDoc.isRecruiterSignatureRequired && (
                  <div>
                    <p className="tag-ds subtitle" style={{ marginBottom: 12, marginTop: 0 }}>
                      {t('candidate_recruiter:APPROVAL.SIGNATURE.signatureByRecruiter')}:
                    </p>
                    {isDocumentSignedByRecruiter ? (
                      <div className="tag-ds status-tag_success" style={{ textTransform: 'uppercase' }}>
                        <span className="material-icons">check_circle</span>
                        {t('candidate_recruiter:APPROVAL.SIGNATURE.signed')}
                      </div>
                    ) : (
                      <div className="tag-ds ">
                        <button
                          onClick={() => {
                            const payload: { document: any; filterName?: string } = { document: doc };

                            if (filterValue) {
                              payload.filterName = filterValue;
                            }

                            signDocument(payload);
                          }}
                          disabled={isLoading || (isSuccess && !isError)}
                        >
                          {t('candidate_recruiter:APPROVAL.SIGNATURE.sign')}
                        </button>
                      </div>
                    )}
                  </div>
                )}
                {selectedDoc.isCandidateSignatureRequired && (
                  <div style={{ marginTop: 32 }}>
                    <p className="tag-ds subtitle" style={{ marginBottom: 8, marginTop: 0 }}>
                      {t('candidate_recruiter:APPROVAL.SIGNATURE.signatureByCandidate')}:
                    </p>

                    {isDocumentSigned ? (
                      <div className="tag-ds status-tag_success" style={{ textTransform: 'uppercase' }}>
                        <span className="material-icons">check_circle</span>
                        {t('candidate_recruiter:APPROVAL.SIGNATURE.signed')}
                      </div>
                    ) : (
                      <div className="tag-ds status-tag_alert" style={{ textTransform: 'uppercase' }}>
                        {t('candidate_recruiter:APPROVAL.SIGNATURE.missing')}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
