import React from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
interface AppPromptProps {
  isVisible: boolean;
  content: {
    title: string;
    description?: string;
  };
  buttonOne?: { onClick: () => void; text: string };
  buttonTwo: { onClick: () => void; text: string };
  onClose: () => void;
}

const AppPrompt: React.FC<AppPromptProps> = ({ isVisible, content, onClose, buttonOne, buttonTwo }) => {
  return (
    <Dialog onClose={onClose} open={isVisible}>
      <DialogTitle style={{ padding: '40px 75px 16px 40px', position: 'relative' }}>
        <span className="tag-ds h4">{content.title}</span>
        <span
          className="material-icons"
          style={{ position: 'absolute', right: 0, top: 6, cursor: 'pointer' }}
          onClick={onClose}
        >
          close
        </span>
      </DialogTitle>
      {content.description && (
        <DialogContent style={{ padding: '0 75px 48px 40px' }}>
          <span className="tag-ds  base">{content.description}</span>
        </DialogContent>
      )}
      <DialogActions style={{ padding: '0 40px 40px 40px' }}>
        <Box display="flex" justifyContent="space-evenly" width={360}>
          {buttonOne && (
            <button onClick={buttonOne.onClick} className="tag-ds button-secondary">
              {buttonOne.text}
            </button>
          )}

          <button onClick={buttonTwo.onClick} className="tag-ds button-primary">
            {buttonTwo.text}
          </button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AppPrompt;
