import React, { useEffect, useMemo, useState, VoidFunctionComponent } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { Utils } from '@helpers/utils';
import { useGetUser } from '@hooks/apiHooks';
import useTelemetry from '@hooks/useTelemetry';
import { EventType, TargetName, TelemetryActor } from '@models/telemetry.model';
import { RootState } from '@store/rootReducer';
import { oneOnboardingSlice, setAppLoaded } from '@store/slices/oneOnboarding.slice';

import { ContactUs } from '../../app/ContactUs/ContactUs';
import { getExculudedJumpConditionSteps } from '../../oneOnboarding/wrappers/FlowWrapper';
import { OONavigationContext, OONavigationContextInterface } from '../context/NavigationContext';
import { useFlowWrapper } from '../hooks/useFlowWrapper';
import { OONavigationPages } from '../interfaces';
import { OneOnboardingRoute } from '../OneOnboardingRoute';
import { OOCandidateOnHold } from './CandidateOnHold';
import { Consents } from './Consents';
import { Dashboard } from './Dashboard';
import { DocumentUploadPage } from './DocumentUploadPage';
import { OOFormDriver } from './FormDriver';
import { LastPage } from './LastPage';
import { OOCandidateSignDocuments } from './SignDocuments/CandidateSignDocuments';

export const NavigationController: VoidFunctionComponent = () => {
  const store = useStore<RootState>();
  const tenantId = localStorage.getItem('tenantId');
  const userId = useSelector((state: RootState) => state.authApp.userId);
  const location = useLocation();
  const history = useHistory();
  const params = useParams<{ step?: string }>();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const dispatch = useDispatch();
  const flowWrapper = useFlowWrapper();
  const debug = location.search.includes('debug=true');
  const applicationId = useSelector((state: RootState) => state.oneOnboarding.applicationId);
  const clientOrganizationId = useSelector((state: RootState) => state.oneOnboarding.clientOrganizationId);
  const selectedConfigurationId = useSelector((state: RootState) => state.oneOnboarding.selectedConfigurationId);
  const appLoaded = useSelector((state: RootState) => state.oneOnboarding.appLoaded);
  const { createTelemetryAuthenticated, createTelemetryUnauthenticated } = useTelemetry();
  useGetUser(tenantId ? parseInt(tenantId) : undefined, userId!);

  const step = params.step;

  useEffect(() => {
    //TODO Move this to custom hook, since its giving length to this component
    const isHiringRoute = location.pathname === '/oo/flow/hire-signature-hire-signature-create';
    const isSignatureRoute = location.pathname === '/oo/flow/signature-create';
    const documentSelectionRoute = location.pathname === '/oo/flow/document-selection-documents-to-upload';

    const candidateIdUrlParam = new URLSearchParams(location.search).get('candidateId');

    if (!tenantId) return;

    if (isHiringRoute && !appLoaded[TargetName.CandidateDocumentsSigning]) {
      createTelemetryUnauthenticated({
        applicationId: applicationId,
        clientOrganizationId: clientOrganizationId,
        configurationId: selectedConfigurationId,
        eventType: EventType.LINK_OPENED,
        tenantId: tenantId as any,
        targetName: TargetName.CandidateDocumentsSigning,
        actor: TelemetryActor.CANDIDATE,
      });

      dispatch(setAppLoaded(TargetName.CandidateDocumentsSigning));
    }

    if (isSignatureRoute) {
      if (!appLoaded[TargetName.SignatureCreate] && candidateIdUrlParam) {
        createTelemetryUnauthenticated({
          applicationId: applicationId,
          clientOrganizationId: clientOrganizationId,
          configurationId: selectedConfigurationId,
          eventType: EventType.LINK_OPENED,
          tenantId: tenantId as any,
          targetName: TargetName.SignatureCreate,
          candidateId: candidateIdUrlParam,
          actor: TelemetryActor.CANDIDATE,
        });
        dispatch(setAppLoaded(TargetName.SignatureCreate));
      }

      createTelemetryAuthenticated({
        applicationId: applicationId,
        clientOrganizationId: clientOrganizationId,
        configurationId: selectedConfigurationId,
        eventType: EventType.PAGE_LOADED,
        tenantId: tenantId as any,
        targetName: TargetName.SignatureCreate,
        actor: TelemetryActor.CANDIDATE,
      });
    }

    if (documentSelectionRoute) {
      createTelemetryAuthenticated({
        applicationId: applicationId,
        clientOrganizationId: clientOrganizationId,
        configurationId: selectedConfigurationId,
        eventType: EventType.PAGE_LOADED,
        tenantId: tenantId as any,
        targetName: TargetName.CandidateDocumentsSelection,
        actor: TelemetryActor.CANDIDATE,
      });
    }
  }, [
    appLoaded,
    applicationId,
    clientOrganizationId,
    selectedConfigurationId,
    createTelemetryAuthenticated,
    createTelemetryUnauthenticated,
    dispatch,
    tenantId,
    userId,
    location,
  ]);

  const navigationPages = useMemo(() => {
    if (!flowWrapper) return [];
    return flowWrapper.getNavigationPages();
  }, [flowWrapper]);

  const navPagesToRoutes = (pages: string[]) => pages.map((i) => i.replaceAll('_', '-').toLowerCase());

  const navigationRoutes = useMemo(() => {
    return navPagesToRoutes(navigationPages);
  }, [navigationPages]);

  useEffect(() => {
    if (navigationPages.length === 0) return;
    if (!flowWrapper) return;

    if (step) {
      const index = navigationRoutes.indexOf(step as OONavigationPages);
      if (index !== -1) {
        setCurrentPageIndex(index);
        const stepAndFormFromUrl = flowWrapper.getCurrentStepAndFormFromNavigationPage(navigationPages[index]);
        if (index !== 0) {
          const candidateSteps = flowWrapper.steps.filter((s) => s.role.includes('candidate')).map((s) => s.name);
          if (candidateSteps.includes(stepAndFormFromUrl.step as any)) {
            dispatch(oneOnboardingSlice.actions.setCurrentForm(stepAndFormFromUrl as any));
          }
        } else {
          dispatch(
            oneOnboardingSlice.actions.setCurrentForm({
              step: 'CANDIDATE_DETAILS',
              form: 'PERSONAL_INFO',
            }),
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, navigationPages, navigationRoutes]);

  const currentPage = useMemo(() => {
    return navigationPages[currentPageIndex] != null ? navigationPages[currentPageIndex] : null;
  }, [currentPageIndex, navigationPages]);

  if (!flowWrapper || !currentPage) {
    return <></>;
  }
  const changeBrowserPageTo = (navigationPageIndex: number) => {
    const navigationRoutes = navPagesToRoutes(flowWrapper.getNavigationPages());
    history.push(`/oo/flow/${navigationRoutes[navigationPageIndex]}${location.search ? location.search : ''}`, {
      overview: false,
    });
  };
  const navigationContextValue: OONavigationContextInterface = {
    currentPage,
    to: (page: OONavigationPages) => {
      Utils.consoleLog('Navigation#to', page);
      changeBrowserPageTo(navigationPages.indexOf(page));
    },
    toNextIndex: () => {
      Utils.consoleLog('Navigation#toNextIndex', currentPage);
      changeBrowserPageTo(navigationPages.indexOf(currentPage) + 1);
    },
    toPrevIndex: () => {
      Utils.consoleLog('Navigation#toPrevIndex', currentPage);
      changeBrowserPageTo(navigationPages.indexOf(currentPage) - 1);
    },
    forward: () => {
      Utils.consoleLog(
        'Navigation#forward ',
        currentPage,
        store.getState().oneOnboarding.step,
        store.getState().oneOnboarding.form,
      );
      if (currentPageIndex === navigationPages.length - 1) {
        Utils.consoleLog('Cant go forward as we are on last page');
        return;
      }

      const nextStepAndForm = flowWrapper.getNextStepAndForm(
        store.getState().oneOnboarding.step,
        store.getState().oneOnboarding.form,
      );
      if (!nextStepAndForm) {
        throw new Error('No next step or form found');
      }
      if (nextStepAndForm.step === null) {
        history.push('/oo/dashboard');
      }
      const exculudedJumpConditionSteps = getExculudedJumpConditionSteps(flowWrapper.steps);
      const candidateSteps = flowWrapper.steps
        .filter((s) => s.role.includes('candidate') && !exculudedJumpConditionSteps.includes(s.name))
        .map((s) => s.name);
      if (candidateSteps.includes(nextStepAndForm.step)) {
        dispatch(
          oneOnboardingSlice.actions.setCurrentForm({
            step: nextStepAndForm.step,
            form: nextStepAndForm.form,
          }),
        );
      }
      changeBrowserPageTo(currentPageIndex + 1);
    },
    back: () => {
      if (currentPageIndex <= 0) return;
      const prevStepAndForm = flowWrapper.getPrevStepAndForm(
        store.getState().oneOnboarding.step,
        store.getState().oneOnboarding.form,
      );
      if (!prevStepAndForm) {
        throw new Error('No next step or form found');
      }
      const candidateSteps = flowWrapper.steps.filter((s) => s.role.includes('candidate')).map((s) => s.name);
      if (candidateSteps.includes(prevStepAndForm.step)) {
        dispatch(
          oneOnboardingSlice.actions.setCurrentForm({
            step: prevStepAndForm.step,
            form: prevStepAndForm.form,
          }),
        );
      }
      changeBrowserPageTo(currentPageIndex - 1);
    },
    toDocumentTypeSelection: () => {
      Utils.consoleLog('Navigation#toDocumentTypeSelection');
      changeBrowserPageTo(navigationPages.indexOf('DOCUMENT_UPLOAD_TYPE_SELECTION'));
    },
    toDocumentTypeUpload: () => {
      Utils.consoleLog('Navigation#toDocumentUpload');
      changeBrowserPageTo(navigationPages.indexOf('DOCUMENT_UPLOAD'));
    },
  };

  const routes: Array<{ route: string; component: any }> = [];

  flowWrapper.steps
    .filter((s) => s.role.includes('candidate'))
    .forEach((step) => {
      const routeName = step.name.replaceAll('_', '-').toLowerCase();
      if (step.documentations.length > 0) {
        routes.push({ route: `/${routeName}`, component: DocumentUploadPage });
      }
      if (step.documentsForSignature.length > 0) {
        routes.push({ route: `/${routeName}`, component: OOCandidateSignDocuments });
      }
    });

  navigationRoutes.forEach((x) => routes.push({ route: `/${x}`, component: OOFormDriver }));

  return (
    <OONavigationContext.Provider value={navigationContextValue}>
      {debug && (
        <>
          <code>
            <pre>
              {JSON.stringify(
                {
                  step,
                  currentPage,
                  currentPageIndex,
                  navigationPages,
                  navigationRoutes,
                  routePaths: routes.map((item) => '/oo/flow' + item.route),
                },
                null,
                2,
              )}
            </pre>
          </code>
        </>
      )}
      <Switch>
        <Route path="/oo/flow/dashboard" component={Dashboard} />;
        <Route path="/oo/flow/end" component={LastPage} />;
        <Route path="/oo/flow/contact-us" component={ContactUs} />;
        <Route path="/oo/flow/candidate-on-hold" component={OOCandidateOnHold} />;
        <Route path="/oo/flow/consents" component={Consents} />;
        {routes.map((item, index) => (
          <OneOnboardingRoute
            key={index}
            exact={false}
            isAuthenticated={true}
            redirectTo="/flow"
            path={`/oo/flow${item.route}`}
            component={item.component}
            {...flowWrapper.getCurrentStepAndFormFromNavigationPage(currentPage)}
          />
        ))}
      </Switch>
    </OONavigationContext.Provider>
  );
};
