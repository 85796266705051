import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Formik, FormikErrors, FormikHelpers } from 'formik';
import { isEmpty } from 'lodash';
import isEqual from 'lodash/isEqual';
import { useStore } from 'react-redux';
import { useInjection } from '@context/inversify-context-provider';
import { Box, Button, Grid } from '@mui/material';
import { RootState } from '@store/rootReducer';
import { setUnsavedChangesExist } from '@store/slices/oneOnboarding.slice';
import { useQueryClient } from '@tanstack/react-query';

import BottomBox from '../../../components/shared/BottomBox/BottomBox';
import useBackendTranslations from '../../../hooks/useBackendTranslations';
import { AamBackendApi } from '../../../libs/aamBackendApi';
import { OONavigationContext } from '../context/NavigationContext';
import { editableCondition, mandatoryCondition, renderCondition } from '../hooks/useRenderCondition';
import { useT } from '../hooks/useT';
import { Component, OOSteps } from '../interfaces';
import { OOControlModel } from '../models/ControlModel';
import { OOPageModel } from '../models/FormModel';
import { OOFlowWrapper } from '../wrappers/FlowWrapper';
import { OOFormControl } from './FormControl';

interface OOFormProps {
  form: OOPageModel;
  flowWrapper: OOFlowWrapper;
  forwardOnSubmit?: boolean;
  step?: OOSteps;
  fromSelfService?: boolean;
}

export const OOForm: React.VoidFunctionComponent<OOFormProps> = ({
  form,
  flowWrapper,
  forwardOnSubmit,
  step,
  fromSelfService,
}: OOFormProps) => {
  const { t, i18n } = useT('entry', 'documents', 'approval', 'hiring', 'candidate_recruiter');
  const navigationContext = React.useContext(OONavigationContext);
  const aamBackendApi = useInjection(AamBackendApi);
  const store = useStore<RootState>();
  const { controls } = form;
  const [changeCounter, setChangeCounter] = useState<number>(0);
  const [clickCounter, setClickCounter] = useState<number>(0);
  const [manualErrors, setManualErrors] = useState<Map<string, any>>(new Map());
  const [saveEnabled, setSaveEnabled] = useState(true);
  const queryClient = useQueryClient();

  useBackendTranslations(i18n, fromSelfService);

  const formikRef = useRef<any>();

  const formHasCustomSubmitButtons = controls.some(
    (c) => c.isVisibleCandidate && !c.component?.toLowerCase().includes('submit'),
  );

  const [dynamicValidationSchema, setDynamicValidationSchema] = useState(form.getValidationSchema(flowWrapper, t));

  useEffect(() => {
    store.dispatch(setUnsavedChangesExist(!isEqual(formikRef.current?.values, formikRef.current?.initialValues)));
    setDynamicValidationSchema(
      form.getValidationSchema(flowWrapper, t, formikRef.current?.values, undefined, manualErrors),
    );
    if (formikRef.current) {
      formikRef.current.validateForm(formikRef.current?.values);
    }
  }, [changeCounter, flowWrapper, form, store, t, manualErrors]);

  const controlIsStatic = (control: any) =>
    [Component.StaticText, Component.Accordion, Component.Image].includes(control.component) ||
    !control.isEditableCandidate;

  const formIsStatic = controls.every(controlIsStatic);

  const getPrefix = (label: string) => {
    let prefix = label?.split('.')?.[0].toLowerCase() || '';
    const prefixAlias: any = {
      documents2: 'documents',
      documents3: 'documents',
    };
    if (prefixAlias[prefix]) {
      prefix = prefixAlias[prefix];
    }
    return prefix;
  };

  const hasJumpCondition = (currentStepName: string, currentPageName: string): boolean => {
    for (const step of flowWrapper?.steps ?? []) {
      if (step.name === currentStepName && !!step.jumpCondition) {
        return true;
      }
      for (const page of step.pages ?? []) {
        if (step.name === currentStepName && page.name === currentPageName) {
          return !!page.jumpCondition;
        }
      }
    }
    return false;
  };
  const submitHandler = async (values: Record<string, any>, actions: FormikHelpers<Record<string, any>>) => {
    const data = {
      data: flowWrapper?.generateArrayValues(values),
      currentStep: step || store.getState().oneOnboarding.step,
      currentPage: form.name,
      skipForward: forwardOnSubmit === false || values['SKIP_FORWARD'] === true,
    };
    OOFlowWrapper.prepareValues(controls, data.data);
    // errorsOnSave added for error messages to be displayed, because when we have error in array control it is not displayed on save
    let errorsOnSave;
    try {
      await aamBackendApi.updateUserFlow(
        store.getState().oneOnboarding.clientOrganizationId!,
        store.getState().oneOnboarding.selectedConfigurationId!,
        store.getState().authApp.userId!,
        data,
      );

      if (hasJumpCondition(data.currentStep, data.currentPage)) {
        await queryClient.invalidateQueries(['getFlowAndUpdateRedux', store.getState().authApp.userId!]);
      }
      if (forwardOnSubmit !== false && values['SKIP_FORWARD'] !== true) {
        navigationContext.forward();
      }
    } catch (err) {
      if (err?.response?.data?.error?.data?.message) {
        const errors = JSON.parse(err.response.data.error.data.message);

        if (Object.keys(errors).length > 0) {
          const controlStatus: Record<string, any> = {};
          Object.keys(errors).forEach((x) => {
            const control = controls.find((c) => c.name === x);
            if (errors[x].message === 'required' && control?.mandatoryValidationError) {
              controlStatus[x] = t(control.mandatoryValidationError);
              return;
            }
            const prefix = getPrefix(errors[x].message);
            controlStatus[x] = t(`${prefix}:${errors[x].message}`) || '';
          });
          actions.setErrors(controlStatus);
          errorsOnSave = controlStatus;
        }
      }
    } finally {
      actions.setSubmitting(false);
      // 'SKIP_FORWARD' is set as a form value, but must be one-time exception (one submission)
      // so we remove it to unlock the form for the progress on the next submission
      if (values['SKIP_FORWARD'] && !errorsOnSave) {
        formikRef.current.setFieldValue('SKIP_FORWARD', undefined);
      }
    }
  };

  const dismissHandler = async (
    setErrors: (errors: FormikErrors<Record<string, any>>) => void,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    setErrors({});

    const data = {
      data: {},
      currentStep: step || store.getState().oneOnboarding.step,
      currentPage: form.name,
      isDismiss: true,
    };
    try {
      await aamBackendApi.updateUserFlow(
        store.getState().oneOnboarding.clientOrganizationId!,
        store.getState().oneOnboarding.selectedConfigurationId!,
        store.getState().authApp.userId!,
        data,
      );
      if (forwardOnSubmit !== false) {
        navigationContext.forward();
      }
    } finally {
      setSubmitting(false);
    }
  };

  const isCandidateControl = (control: OOControlModel) => {
    return (
      control.isVisibleCandidate &&
      !control.component?.toLowerCase().includes('submit') &&
      !control.component?.includes(Component.DismissButton)
    );
  };

  const isCandidateSubmitOrDismissBtn = (control: OOControlModel) => {
    return (
      control.isVisibleCandidate &&
      (control.component?.toLowerCase().includes('submit') ||
        (forwardOnSubmit !== false && control.component?.includes(Component.DismissButton)))
    );
  };

  const isCandidateDismissBtn = (control: OOControlModel) => {
    return (
      forwardOnSubmit !== false && control.isVisibleCandidate && control.component?.includes(Component.DismissButton)
    );
  };

  const onFormChange = () => {
    setChangeCounter((prevValue: number) => prevValue + 1);
  };

  const initialValues = useMemo(() => {
    return form.getInitialValues(flowWrapper);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowWrapper]);

  useEffect(() => {
    const el = document.querySelector('.MuiFormHelperText-root.Mui-error');
    (el?.parentElement ?? el)?.scrollIntoView(true);
  }, [clickCounter]);

  const isSaveEnabled = () => {
    const values = formikRef?.current?.values;
    if (!values) return;
    const controlsFiltered = controls
      .filter((c) => c.isVisibleRecruiter && renderCondition(c, flowWrapper, values))
      .map((control: OOControlModel) => {
        control.isMandatory = mandatoryCondition(control, flowWrapper, values) ?? control.isMandatory;
        flowWrapper.getDisabledFieldForDE(control, flowWrapper);
        return control;
      });

    const isEditable = controlsFiltered.some((control) => {
      const editableResult =
        flowWrapper?.isControlEditable(control.isEditableCandidate, control.isEditableRecruiter) ?? true;
      const editableConditionResult = editableCondition(control, flowWrapper, values);
      return editableResult || editableConditionResult;
    });

    const jumpCondition = form?.jumpCondition;
    const navigationPage = navigationContext.currentPage;
    const currentPage = store.getState().oneOnboarding.currentForm;

    if (!isEditable && jumpCondition && navigationPage !== currentPage) setSaveEnabled(false);
  };

  useMemo(() => {
    isSaveEnabled();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikRef?.current?.values, controls]);

  return (
    <Formik
      enableReinitialize={true}
      validateOnMount={true}
      innerRef={formikRef}
      validateOnChange={true}
      initialValues={initialValues}
      validationSchema={dynamicValidationSchema}
      onSubmit={submitHandler}
    >
      {({ handleSubmit, isSubmitting, values, setErrors, setSubmitting, errors, setTouched, touched }) => (
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          onChange={onFormChange}
          onClick={(e) => {
            onFormChange();
            if (!e || !(e?.target instanceof HTMLButtonElement)) {
              return;
            }

            const dataTestid = e?.target?.getAttribute('data-testid') ?? '';
            if (!['submitButton'].includes(dataTestid)) {
              return;
            }

            const addTouched = {} as Record<string, any>;
            Object.keys(errors).forEach((key: string) => {
              addTouched[key] = true;
            });
            if (!isEmpty(addTouched)) {
              setTouched({ ...touched, ...addTouched }, true);
              setClickCounter((prevValue: number) => prevValue + 1);
            }
          }}
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <Box
            style={{
              display: 'grid',
              gap: '20px',
              paddingTop: '5px',
              paddingBottom: '20px',
              flexGrow: 1,
              alignContent: 'start',
            }}
          >
            {controls
              .filter((c) => isCandidateControl(c) && renderCondition(c, flowWrapper, values))
              .map((control: OOControlModel) => {
                control.isMandatory = mandatoryCondition(control, flowWrapper, values) ?? control.isMandatory;
                flowWrapper.getDisabledFieldForDE(control, flowWrapper);
                return control;
              })
              .map((control: OOControlModel) => {
                return (
                  <Grid key={control.name} item xs={12} style={{ minWidth: '100%' }}>
                    <OOFormControl
                      control={control}
                      otherFormControls={form.controls}
                      filterSubControls={(c) => isCandidateControl(c)}
                      setManualErrors={setManualErrors}
                    />
                  </Grid>
                );
              })}
          </Box>
          {form?.isLastPage && formHasCustomSubmitButtons ? (
            <Grid container item xs={12}>
              <Box py={2} flexGrow={1}>
                {controls
                  .filter((c) => isCandidateSubmitOrDismissBtn(c))
                  .map((control: OOControlModel, index) => {
                    const prefix = getPrefix(control?.label);
                    let el: JSX.Element;
                    if (control.component === Component.SubmitButton) {
                      el = (
                        <Button
                          data-testid="submitButton"
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                        >
                          {control.label
                            ? t(`${prefix}:${control.label}`)
                            : t('candidate_recruiter:GENERAL.GENERIC.submit')}
                        </Button>
                      );
                    } else if (control.component === Component.SubmitLink) {
                      el = (
                        <Button
                          data-testid="submitButton"
                          type="button"
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                          onClick={() => {
                            window.open(control.link || '', '_blank');
                          }}
                        >
                          {t(`${prefix}:${control.label}`)}
                        </Button>
                      );
                    } else if (control.component === Component.DismissButton) {
                      el = (
                        <Button
                          data-testid="dismissButton"
                          variant="outlined"
                          color="primary"
                          disabled={isSubmitting}
                          onClick={() => dismissHandler(setErrors, setSubmitting)}
                        >
                          {control.label
                            ? t(`${prefix}:${control.label}`)
                            : t('candidate_recruiter:GENERAL.GENERIC.dismiss')}
                        </Button>
                      );
                    } else {
                      el = <OOFormControl control={control} setManualErrors={setManualErrors} />;
                    }
                    return (
                      <Box key={index} py={1}>
                        {el}
                      </Box>
                    );
                  })}
              </Box>
            </Grid>
          ) : !form?.isLastPage ? (
            <BottomBox marginBottom="max(60px, env(safe-area-inset-bottom))">
              <Button
                data-testid="submitButton"
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || !saveEnabled}
              >
                {t(
                  formIsStatic
                    ? 'candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.continue'
                    : 'candidate_recruiter:GENERAL.GENERIC.submit',
                )}
              </Button>
              {controls
                .filter((c) => isCandidateDismissBtn(c))
                .map((control: OOControlModel, index) => {
                  return (
                    <Box py={1} key={index}>
                      <Button
                        data-testid="dismissButton"
                        variant="outlined"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={() => dismissHandler(setErrors, setSubmitting)}
                      >
                        {control.label
                          ? t(`${getPrefix(control.label)}:${control.label}`)
                          : t('candidate_recruiter:GENERAL.GENERIC.dismiss')}
                      </Button>
                    </Box>
                  );
                })}
            </BottomBox>
          ) : (
            <></>
          )}
        </form>
      )}
    </Formik>
  );
};
