import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from '../DocumentPages.module.scss';

interface PreviewActionsProps {
  imageControls: {
    zoom: number;
    rotate: number;
    x: number;
    y: number;
  };
  currentActiveImageIndex: number;
  imagesLength: number;
  selectedImageName: string;
  handleImageControls: (type: 'zoom' | 'rotate', value: number) => void;
  zoomValue: () => number;
}

const PreviewActions: React.FC<PreviewActionsProps> = ({
  imageControls,
  currentActiveImageIndex,
  imagesLength,
  selectedImageName,
  handleImageControls,
  zoomValue,
}) => {
  const { t } = useTranslation(['documents']);
  const isZoomDisabled = (direction: 'zoomIn' | 'zoomOut') => {
    if (direction === 'zoomIn') {
      return imageControls.zoom === 9;
    } else {
      return imageControls.zoom === -7;
    }
  };

  return (
    <div className={styles.previewContainer__controls}>
      <div className={styles.previewContainer__controlsInfo}>
        <span className="tag-ds  medium">{selectedImageName}</span>
        <span className="tag-ds  base">
          | {currentActiveImageIndex} of {imagesLength}
        </span>
      </div>
      <div className={styles.previewContainer__controlsActions}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span
            className={`material-icons ${styles.previewContainer__controlsActionsButton} ${
              isZoomDisabled('zoomOut') && styles.previewContainer__controlsActionsButtonDisabled
            }`}
            onClick={() => handleImageControls('zoom', -1)}
          >
            remove_circle_outline
          </span>
          <p className="tag-ds  base" style={{ margin: '0 16px' }}>
            {Math.floor(zoomValue() * 100)}%
          </p>

          <span
            className={`material-icons ${styles.previewContainer__controlsActionsButton}
                    ${isZoomDisabled('zoomIn') && styles.previewContainer__controlsActionsButtonDisabled}
                    
                    `}
            onClick={() => handleImageControls('zoom', 1)}
          >
            control_point
          </span>
        </div>
        <div style={{ display: 'flex' }}>
          <span className="tag-ds  medium" style={{ marginRight: 16 }}>
            {t('actions.rotate')}
          </span>
          <span
            className={`material-icons ${styles.previewContainer__controlsActionsButton}`}
            onClick={() => handleImageControls('rotate', -90)}
            style={{ marginRight: 16 }}
          >
            rotate_left
          </span>
          <span
            className={`material-icons ${styles.previewContainer__controlsActionsButton}`}
            onClick={() => handleImageControls('rotate', 90)}
          >
            rotate_right
          </span>
        </div>
      </div>
    </div>
  );
};

export default PreviewActions;
