import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mutateRejectUser, useGetRejectReasons } from '@hooks/apiHooks';
import { Box, TextField } from '@mui/material';

import AdminPrompt from '../AdminPrompt/AdminPrompt';
import { AdminReactiveSelect } from '../AdminReactiveSelect/AdminReactiveSelect';

interface AdminRejectReasonPromptProps {
  clientOrganizationId: string;
  configurationId: string;
  userId: string;
  applicationId: string;
  isVisible: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

export const AdminRejectReasonPrompt: FC<AdminRejectReasonPromptProps> = ({
  clientOrganizationId,
  configurationId,
  userId,
  applicationId,
  isVisible,
  onClose,
  onSuccess,
}) => {
  const REJECT_OTHER = 'rejectOther';
  const [rejectReason, setRejectReason] = useState<string>('');
  const [rejectReasonOther, setRejectReasonOther] = useState<string>('');

  const { data: rejectReasons } = useGetRejectReasons(clientOrganizationId, configurationId);
  const { t } = useTranslation(['recruiter']);

  const { mutate: rejectUser, isLoading: rejecting } = mutateRejectUser(
    clientOrganizationId,
    configurationId,
    userId,
    applicationId,
    rejectReason !== REJECT_OTHER ? rejectReason : rejectReasonOther,
    () => {
      setRejectReason('');
      setRejectReasonOther('');
      if (onSuccess) {
        onSuccess();
      }
    },
  );

  return (
    <AdminPrompt
      action={{
        text: t('recruiter:GENERAL.ONBOARDING_REJECTION.REJECTION_MODAL.action.confirm'),
        cancelText: t('recruiter:GENERAL.ONBOARDING_REJECTION.REJECTION_MODAL.action.cancel'),
        onClick: () => {
          rejectUser();
        },
        disabled:
          ['', undefined].includes(rejectReason) ||
          (rejectReason === REJECT_OTHER && rejectReasonOther?.length < 3) ||
          rejecting,
      }}
      content={{
        title: t('recruiter:GENERAL.ONBOARDING_REJECTION.REJECTION_MODAL.title'),
        description: (
          <Box>
            <p>{t('recruiter:GENERAL.ONBOARDING_REJECTION.REJECTION_MODAL.text1')}</p>
            <p>{t('recruiter:GENERAL.ONBOARDING_REJECTION.REJECTION_MODAL.text2')}</p>
            <Box className="dialog-form">
              <AdminReactiveSelect
                value={rejectReason}
                label={t('recruiter:GENERAL.ONBOARDING_REJECTION.REJECTION_MODAL.select.label')}
                name="reject-reason"
                placeholder={t('recruiter:GENERAL.ONBOARDING_REJECTION.REJECTION_MODAL.select.placeholder')}
                onChange={(e) => setRejectReason((e as any).target.value)}
                options={[
                  ...(rejectReasons || []).map((rejectReasonOption) => ({
                    name: t(
                      'recruiter:GENERAL.ONBOARDING_REJECTION.REJECTION_MODAL.select.options.' + rejectReasonOption,
                    ),
                    value: rejectReasonOption,
                  })),
                ]}
              />
              {rejectReason === REJECT_OTHER && (
                <TextField
                  id="outlined-textarea"
                  onChange={(e) => setRejectReasonOther(e.target.value)}
                  placeholder={t('recruiter:GENERAL.ONBOARDING_REJECTION.REJECTION_MODAL.other.placeholder')}
                  inputProps={{ maxLength: 2048 }}
                  multiline
                />
              )}
            </Box>
          </Box>
        ),
      }}
      isVisible={isVisible ? isVisible : false}
      onClose={() => {
        setRejectReason('');
        setRejectReasonOther('');
        onClose();
      }}
    />
  );
};
