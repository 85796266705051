import React, { useEffect, useMemo, useState } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Header } from '@components/app/Header/Header';
import { useInjection } from '@context/inversify-context-provider';
import { setMomentLocale } from '@helpers/date-locales.helper';
import { getTenantDefaultLanguage, getTenantLanguageList } from '@helpers/tenant.helper';
import { useFetchSelectedConfiguration } from '@hooks/apiHooks';
import { Box, Container, Grid } from '@mui/material';
import { RootState } from '@store/rootReducer';
import { authAppSlice } from '@store/slices/authApp.slice';
import { setApplicationId } from '@store/slices/oneOnboarding.slice';

import AppPrompt from '../../components/app/AppPrompt/AppPrompt';
import { AamBackendApi } from '../../libs/aamBackendApi';
import { store } from '../../store';
import { CustomLogo } from '../oneOnboarding/controls/CustomLogo';
import { useFlowWrapper } from '../oneOnboarding/hooks/useFlowWrapper';
import { OOStepsEnum } from '../oneOnboarding/interfaces';
import { OOStepElementModel } from '../oneOnboarding/models/StepElementModel';

export const Settings = () => {
  const [deletionPrompt, setDeletionPrompt] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string>();
  const [preRegisterLanguages, setPreRegisterLanguages] = useState<{ name: string; code: string }[]>([]);

  const tenant = useSelector((state: RootState) => state.tenant);
  const { userId } = useSelector((state: RootState) => state.authApp)!;
  const { logo, flow, step, languages, clientOrganizationId, selectedConfigurationId } = useSelector(
    (state: RootState) => state.oneOnboarding,
  );
  const { t } = useTranslation(['candidate']);
  const aamBackendApi = useInjection(AamBackendApi);
  const history = useHistory();
  const flowWrapper = useFlowWrapper();

  const { data: selectedConfiguration } = useFetchSelectedConfiguration(
    clientOrganizationId || '',
    selectedConfigurationId || '',
  );

  useEffect(() => {
    setSelectedLanguage(getTenantDefaultLanguage(tenant.tenantId));
  }, [tenant.tenantId]);

  useEffect(() => {
    if (i18n?.services?.languageUtils) {
      const langFromStorage = localStorage.getItem('i18nextLng') || getTenantDefaultLanguage(tenant.tenantId);
      const lang = (getTenantLanguageList(tenant.tenantId) as string[]).includes(langFromStorage)
        ? langFromStorage
        : getTenantDefaultLanguage(tenant.tenantId);
      i18n.changeLanguage(lang);
      setSelectedLanguage(lang);
      setMomentLocale(tenant.tenantId, lang);
    }
  }, [tenant.tenantId]);

  useEffect(() => {
    const fetchLangs = async () => {
      const clientOrgId = localStorage.getItem('clientOrganizationId');

      const langs = clientOrgId
        ? await aamBackendApi.fetchClientOrganizationSelectedLanguages(clientOrgId)
        : await aamBackendApi.fetchSelectedLanguagesForTenant();

      const uniqueLangs = [...new Map(langs.map((item) => [item['name'], item])).values()];
      setPreRegisterLanguages(uniqueLangs.map((l) => ({ name: l.name, code: l.code })));
    };

    fetchLangs();
  }, [aamBackendApi]);

  const stepName: any = useMemo(() => {
    return step.length ? step : flow?.find((s: any) => s.elements.length > 0)?.name || OOStepsEnum.registration;
  }, [step, flow]);

  const elements: OOStepElementModel[] = useMemo(() => {
    if (!flowWrapper || !tenant.useOneOnboarding) {
      return [];
    }
    const currentStepKey: string = stepName;

    return flowWrapper.steps.find((step) => step.name === currentStepKey)?.elements ?? [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepName, flowWrapper]);

  const logOut = async () => {
    await aamBackendApi.invalidateToken();
    store.dispatch(authAppSlice.actions.clear());
    store.dispatch(setApplicationId(null));
    history.push(tenant.useOneOnboarding ? '/oo' : '/app');
  };

  const confirmDeletion = async () => {
    if (userId) {
      tenant.useOneOnboarding
        ? await aamBackendApi.markForDeletion(tenant.tenantId, userId)
        : await aamBackendApi.deleteSelf(tenant.tenantId);
      logOut();
    }
  };

  const changeLanguageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const lang = e.target.value;
    setSelectedLanguage(lang);
    setMomentLocale(tenant.tenantId, lang);
    i18n.changeLanguage(lang);
  };

  const handleProfileDeletionModal = () => setDeletionPrompt((state) => !state);

  const isElementVisible = (elName: string) => {
    if (userId === undefined || userId === null) {
      return false;
    } else {
      return (tenant.useOneOnboarding && elements.some((e) => e.name === elName)) || !tenant.useOneOnboarding;
    }
  };

  const languageOptions = languages?.length > 0 ? languages : preRegisterLanguages;

  const onBackPress = () => {
    if ((history as any).length) {
      history.go(-1);
      return;
    }
    history.push('flow/dashboard');
  };

  return (
    <>
      <Header isAuthenticated onBackPress={onBackPress} {...{ CustomLogo: logo ? <CustomLogo src={logo} /> : null }} />
      <Container maxWidth="xl" style={{}}>
        <Grid container mt={6}>
          <Grid item xs={4}>
            <Box mb={5}>
              <h2 className="tag-ds ">{t('candidate:GENERAL.SETTINGS.settings')}</h2>
            </Box>

            {(isElementVisible('LANGUAGE') || userId === undefined || userId === null) && (
              <Box mb={9}>
                <span className="tag-ds  subtitle">{t('candidate:GENERAL.LANGUAGE.chooseLanguage')}</span>
                <div className="tag-ds radio-group">
                  {languageOptions.map((x, i) => {
                    const isNotLastInList = languages.length !== i + 1;
                    return (
                      <div key={x.code + x.name}>
                        <Box my={4}>
                          <div className="radio-container">
                            <input
                              id={x.code}
                              type="radio"
                              value={x.code}
                              checked={x.code === selectedLanguage}
                              onChange={changeLanguageHandler}
                            />
                            <label htmlFor={x.code} style={{ textTransform: 'capitalize' }}>
                              {x.name}
                            </label>
                          </div>
                        </Box>
                        {isNotLastInList && <div className="separator" />}
                      </div>
                    );
                  })}
                </div>
              </Box>
            )}
            {userId && (
              <Box>
                <span className="tag-ds  subtitle">{t('candidate:GENERAL.CANDIDATE_DELETION.privacy')}</span>
                <Box
                  display="flex"
                  alignItems="center"
                  style={{ cursor: 'pointer' }}
                  onClick={handleProfileDeletionModal}
                >
                  <span className="material-icons" style={{ marginRight: 8 }}>
                    delete_forever
                  </span>
                  <p className="tag-ds  base">{t('candidate:GENERAL.CANDIDATE_DELETION.profileDeletionRequest')}</p>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>

      <AppPrompt
        isVisible={deletionPrompt}
        onClose={handleProfileDeletionModal}
        buttonOne={
          selectedConfiguration?.additionalConfigurations?.hideProfileDeleteButtonCandidate
            ? undefined
            : { text: t('candidate:GENERAL.CANDIDATE_DELETION.deleteProfile'), onClick: confirmDeletion }
        }
        buttonTwo={{ text: t('candidate:GENERAL.CANDIDATE_DELETION.keepProfile'), onClick: handleProfileDeletionModal }}
        content={{
          title: t('candidate:GENERAL.CANDIDATE_DELETION.confirmDeletionRequest'),
          description: selectedConfiguration?.additionalConfigurations?.doYouWantToDeleteProfileMessage
            ? t(selectedConfiguration?.additionalConfigurations?.doYouWantToDeleteProfileMessage)
            : t('candidate:GENERAL.CANDIDATE_DELETION.doYouWantToDeleteProfile'),
        }}
      />
    </>
  );
};
