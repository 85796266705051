import { RecruiterSidebarItem } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/types';
import { AdditionalConfigurations } from '@features/selfService/models/configurationModels';
import { Onboarding } from '@models/onboarding.model';
import { ControlItems } from '@store/apiQuery';

import { OOControlModel } from './models/ControlModel';

export type OOSteps =
  | 'IMPORT'
  | 'REGISTRATION'
  | 'CANDIDATE_DETAILS'
  | 'DOCUMENT_UPLOAD'
  | 'DOCUMENT_SELECTION'
  | 'DOCUMENT_UPLOAD2'
  | 'DOCUMENT_VALIDATION'
  | 'DOCUMENT_VALIDATION2'
  | 'RECRUITER_DOCUMENT_UPLOAD'
  | 'RECRUITER_DOCUMENT_VALIDATION'
  | 'REQUEST_CONFIRMATION'
  | 'AWAIT_CONTRACT'
  | 'CANDIDATE_CONFIRMS_DOCUMENTS'
  | 'SIGNATURE'
  | 'HIRE_SIGNATURE'
  | 'APPROVE'
  | 'HIRE'
  | 'RECRUITER_SIGN_DOCUMENTS'
  | 'CANDIDATE_SIGN_DOCUMENTS'
  | 'MATCH'
  | 'MATCHING_ENDED'
  | 'END'
  | 'FEEDBACK_STEP'
  | 'FEEDBACK'
  | 'MATCH'
  | 'PERSONAL_INFO'
  | 'CONTACT_INFO'
  | 'GENERAL_INFO';

export type OOPages =
  | 'CONSENTS'
  | 'LANGUAGE_SELECTOR'
  | 'WELCOME'
  | 'OFFER'
  | 'CONSENTS1'
  | 'DECISION'
  | 'PERSONAL_INFO'
  | 'CONTACT_INFO'
  | 'EMPLOYMENT_INFO'
  | 'CONTRACT_INFO'
  | 'PAYMENT_INFO'
  | 'GENERAL_INFO'
  | 'CREATE'
  | 'HIRE_LIST_INFO'
  | 'HIRE_SIGNATURE_CREATE';
export type OODocumentations = 'PROOF_OF_IDENTITY' | 'DRIVERS_LICENSE' | 'PROOF_OF_STUDENT';
export type OODocuments =
  | 'passport.name'
  | 'passport.front.name'
  | 'drivers-license.name'
  | 'student-card.name'
  | 'PHYSICAL_CONTRACT';

export enum OOStepsEnum {
  import = 'IMPORT',
  registration = 'REGISTRATION',
  candidateDetails = 'CANDIDATE_DETAILS',
  documentUpload = 'DOCUMENT_UPLOAD',
  documentUploadTwo = 'DOCUMENT_UPLOAD2',
  documentValidation = 'DOCUMENT_VALIDATION',
  documentValidationTwo = 'DOCUMENT_VALIDATION2',
  recruiterDocumentUpload = 'RECRUITER_DOCUMENT_UPLOAD',
  recruiterDocumentValidation = 'RECRUITER_DOCUMENT_VALIDATION',
  requestConfirmation = 'REQUEST_CONFIRMATION',
  signature = 'SIGNATURE',
  hireSignature = 'HIRE_SIGNATURE',
  candidateConfirmsDocuments = 'CANDIDATE_CONFIRMS_DOCUMENTS',
  approve = 'APPROVE',
  hire = 'HIRE',
  awaitContract = 'AWAIT_CONTRACT',
  recruiterSignDocuments = 'RECRUITER_SIGN_DOCUMENTS',
  candidateSignDocuments = 'CANDIDATE_SIGN_DOCUMENTS',
  match = 'END',
  matchHire = 'MATCH',
  documentValidation2 = 'DOCUMENT_VALIDATION2',
  documentValidation2Ended = 'DOCUMENT_VALIDATION2_ENDED',
  feedback = 'FEEDBACK',
}

export enum OOFeaturesEnum {
  entry = 'ENTRY',
  approval = 'APPROVAL',
  documents = 'DOCUMENTS',
  hiring = 'HIRING',
  matching = 'MATCHING',
  survey = 'SURVEY',
}
export type OONavigationPages =
  | 'DASHBOARD'
  | 'CANDIDATE_DETAILS_PERSONAL_INFO'
  | 'CANDIDATE_DETAILS_CONTACT_INFO'
  | 'DOCUMENT_UPLOAD_INTRO'
  | 'DOCUMENT_UPLOAD_SELECTOR'
  | 'DOCUMENT_UPLOAD_TYPE_SELECTION'
  | 'DOCUMENT_UPLOAD'
  | 'DOCUMENT_UPLOAD2'
  | 'DOCUMENT_UPLOAD2_SELECTOR'
  | 'DOCUMENT_UPLOAD2_TYPE_SELECTION'
  | 'RECRUITER_DOCUMENT_UPLOAD'
  | 'REQUEST_CONFIRMATION'
  | 'SIGNATURE_INTRO'
  | 'SIGNATURE_CREATE'
  | 'CANDIDATE_CONFIRMS_DOCUMENTS'
  | 'APPROVE'
  | 'HIRE'
  | 'AWAIT_CONTRACT'
  | 'RECRUITER_SIGN_DOCUMENTS'
  | 'CANDIDATE_SIGN_DOCUMENTS'
  | 'MATCH'
  | 'FEEDBACK_STEP_SURVEY_NPS'
  | 'FEEDBACK_STEP_PRE_SURVEY'
  | 'END';

export type OORegistrationConsentControls =
  | 'PRIVACY_POLICY'
  | 'TERMS_AND_CONDITIONS'
  | 'RECEIVE_INFORMATION'
  | 'MARKETING_AGREEMENT'
  | 'JOB_OFFER';
export type OOCandidateDetailsPersonalInfoControls =
  | 'PAYMENT_BY_CHECK_SELECTED'
  | 'EMAIL'
  | 'CITY_OF_BIRTH'
  | 'BIRTH_NAME'
  | 'SALUTATION'
  | 'FIRST_NAME'
  | 'MIDDLE_NAME'
  | 'LAST_NAME'
  | 'DATE_OF_BIRTH'
  | 'COUNTRY_OF_BIRTH'
  | 'REGION1_OF_BIRTH'
  | 'REGION2_OF_BIRTH'
  | 'REGION3_OF_BIRTH'
  | 'NATIONALITY'
  | 'IS_STUDENT'
  | 'IS_OVER_16_YEARS'
  | 'GENDER'
  | 'MOTHER_TONGUE'
  | 'IS_STATELESS'
  | 'WORKED_FOR_AMAZON'
  | 'WORKED_FOR_ADECCO';

export type OOCandidateDetailsContactInfoControls =
  | 'HANDICAPPED_LABEL_NOTIFICATION'
  | 'STREET'
  | 'STREET_TYPE'
  | 'STREET_NUMBER'
  | 'ADDRESSLINE2'
  | 'ADDRESSLINE3'
  | 'POST_CODE'
  | 'CITY'
  | 'REGION1'
  | 'REGION2'
  | 'REGION3'
  | 'COUNTRY'
  | 'CITIZENSHIP'
  | 'PHONE'
  | 'ALTERNATIVE_PHONE'
  | 'BRANCH'
  | 'EMERGENCY_CONTACT_PHONE'
  | 'EMERGENCY_CONTACT_NAME'
  | 'EMERGENCY_CONTACT_RELATION'
  | 'IS_EUCITIZEN';

export type OOCandidateDetailsPaymentInfoControls =
  | 'SOCIAL_SECURITY_NUMBER'
  | 'NATIONAL_IDENTIFIER'
  | 'NATIONAL_IDENTIFIER_TYPE'
  | 'INSURANCE_NAME'
  | 'INSURANCE_NUMBER'
  | 'INSURANCE_EXPIRATION_DATE'
  | 'TAX_NUMBER'
  | 'IBAN'
  | 'BANK_NAME'
  | 'BANK_BIC'
  | 'PAYMENT_METHOD'
  | 'IS_NATIONAL_ID_VALID'
  | 'IS_IBAN_VALID'
  | 'IS_VALID_SSN';

export type OOHiringListDataControls = 'HCR_ID' | 'SUPERVISOR_LOGIN';

export type OOHiringDataControls =
  | 'MONTHLY_HOURS'
  | 'DAYS_PR_WEEK'
  | 'HOURS_PR_WEEK'
  | 'HOURS_PR_DAY'
  | 'SHIFT'
  | 'START_DATE'
  | 'END_DATE'
  | 'TEAM'
  | 'PREFECTURE_POST_CODE'
  | 'ROLE_1'
  | 'ROLE_2'
  | 'ROLE_3';

export type OOCandidateUploadDetailsControls = 'EXPIRATION_DATE';
export type OOCandidateGeneralInfoControls =
  | 'SHIFTS'
  | 'MEDICAL_RESTRICTIONS'
  | 'JOB_TYPES'
  | 'MEDICAL'
  | 'HANDICAPPED'
  | 'AVAILABLE_COMMUNICATION';

export type OOCandidateDetailsEmploymentInfoControls = 'AVAILABLE_FROM' | 'AFFILIATION' | 'JOB_TYPE';
export type OOCandidateDetailsContractInfoControls = 'START_DATE';
export type OOControls =
  | OORegistrationConsentControls
  | OOCandidateDetailsPersonalInfoControls
  | OOCandidateDetailsContactInfoControls
  | OOCandidateDetailsPaymentInfoControls
  | OOCandidateDetailsEmploymentInfoControls
  | OOCandidateDetailsContractInfoControls
  | OOCandidateUploadDetailsControls
  | OOCandidateGeneralInfoControls
  | OOHiringListDataControls
  | OOHiringDataControls;

export enum ControlCategory {
  standard = 'standard',
  custom = 'custom',
  document = 'document',
  calculated = 'calculated',
  static = 'static',
  array = 'array',
}

export enum ControlHeaderEnum {
  province = 'PROVINCE',
  municipality = 'MUNICIPALITY',
}

export type ControlType = 'string' | 'date' | 'selector' | 'boolean' | 'select' | 'number';

export type OOControlValues = {
  // registration
  PRIVACY_POLICY: boolean;
  TERMS_AND_CONDITIONS: boolean;
  RECEIVE_INFORMATION: boolean;
  MARKETING_AGREEMENT: boolean;
  JOB_OFFER: boolean;
  // candidate details
  EMAIL: string;
  PAYMENT_BY_CHECK_SELECTED: boolean;
  HANDICAPPED_LABEL_NOTIFICATION: boolean;
  SALUTATION: string;
  FIRST_NAME: string;
  MIDDLE_NAME: string;
  LAST_NAME: string;
  BIRTH_NAME: string;
  DATE_OF_BIRTH: string;
  CITY_OF_BIRTH: string;
  COUNTRY_OF_BIRTH: string;
  REGION1_OF_BIRTH: string;
  REGION2_OF_BIRTH: string;
  REGION3_OF_BIRTH: string;
  NATIONALITY: string;
  IS_STUDENT: boolean;
  WORKED_FOR_AMAZON: boolean;
  WORKED_FOR_ADECCO: boolean;
  IS_OVER_16_YEARS: boolean;
  GENDER: string;
  MOTHER_TONGUE: string;
  IS_STATELESS: boolean;
  // contact info
  STREET: string;
  STREET_TYPE: string;
  STREET_NUMBER: string;
  ADDRESSLINE2: string;
  ADDRESSLINE3: string;
  POST_CODE: string;
  CITY: string;
  REGION1: string;
  REGION2: string;
  REGION3: string;
  COUNTRY: string;
  CITIZENSHIP: string;
  PHONE: string;
  ALTERNATIVE_PHONE: string;
  BRANCH: string;
  EMERGENCY_CONTACT_PHONE: string;
  EMERGENCY_CONTACT_NAME: string;
  EMERGENCY_CONTACT_RELATION: string;
  IS_EUCITIZEN: boolean;
  // employment info
  JOB_TYPE: string;
  AVAILABLE_FROM: string;
  AFFILIATION: string;
  // contract info
  START_DATE: string;
  //upload details
  EXPIRATION_DATE: string;
  //payment info
  SOCIAL_SECURITY_NUMBER: string;
  NATIONAL_IDENTIFIER: string;
  NATIONAL_IDENTIFIER_TYPE: string;
  INSURANCE_NAME: string;
  INSURANCE_NUMBER: string;
  INSURANCE_EXPIRATION_DATE: string;
  TAX_NUMBER: string;
  IBAN: string;
  BANK_NAME: string;
  BANK_BIC: string;
  PAYMENT_METHOD: string;
  IS_NATIONAL_ID_VALID: boolean;
  IS_IBAN_VALID: boolean;
  IS_VALID_SSN: boolean;
  // hiring data
  MONTHLY_HOURS: string;
  DAYS_PR_WEEK: string;
  HOURS_PR_WEEK: string;
  HOURS_PR_DAY: string;
  SHIFT: string;
  AVAILABLE_COMMUNICATION: string;
  // START_DATE: string;
  TEAM: string;
  END_DATE: string;
  // hiring list data
  HCR_ID: string;
  SUPERVISOR_LOGIN: string;
  // general info
  SHIFTS: Array<string>;
  AVAILABLE_COMMUNICATIONS: Array<string>;
  JOB_TYPES: Array<string>;
  MEDICAL_RESTRICTIONS: Array<string>;
  MEDICAL: boolean;
  HANDICAPPED: boolean;
  PREFECTURE_POST_CODE: string;
  ROLE_1: string;
  ROLE_2: string;
  ROLE_3: string;
};

export interface OOControlModelInterface {
  name: OOControls | string;
  originalName?: string;
  category: ControlCategory;
  isMandatory: boolean;
  isEditableCandidate: boolean;
  isEditableRecruiter: boolean;
  isVisibleCandidate: boolean;
  isVisibleRecruiter: boolean;
  type: ControlType;
  label: string;
  link: string;
  description: string;
  sourceClass: string;
  sourceField: string;
  integrationTarget: string;
  messageField: string;
  controlItemName?: string;
  controlHeaderId?: string;
  controlHeaderName?: string;
  minLength: number | Date;
  maxLength: number | Date;
  regexValidation?: string;
  validationEndpoint?: number;
  displayFormat?: number;
  value: any;
  minOffset?: number;
  maxOffset?: number;
  renderCondition?: Array<Record<string, any>>;
  mandatoryCondition?: Array<Record<string, any>>;
  editableCondition?: Array<Record<string, any>>;
  completeCondition?: Array<Record<string, any>>;
  calculationClassUI?: { name: string };
  validationErrors?: Record<string, any>;
  staticText?: string;
  imageSrc?: string;
  min?: number;
  max?: number;
  controlHeaderClass?: string;
  documentId?: string;
  filterCondition?: Array<Record<string, any>>;
  regExpValidationError?: Record<string, any>;
  minMaxLengthValidationError?: Record<string, any>;
  minMaxOffsetValidationError?: Record<string, any>;
  minMaxValidationError?: Record<string, any>;
  customValidationError?: Record<string, any>;
  mandatoryValidationError?: Record<string, any>;
  topSelectValue?: string;
  defaultSelectValue?: string;
  translationSelectItems?: string;
  reducerSelectItemsMap?: Record<string, any>;
  excludedSelectItems?: Record<string, any>;
  parameters?: Array<Record<string, any>>;
  populatedLabel?: string;
  defaultValue?: string;
  subControls?: Array<OOControlModel> | Array<Array<OOControlModel>>;
  parentFormName?: string;
  parentFormIndex?: number;
  component: Component;
  customStyles?: Array<string>;
  dropdownOptions?: ControlItems;
  sequence?: number;
  maxSubControls?: number;
}

export interface TextFieldControlModelInterface extends OOControlModelInterface {
  type: 'string' | 'number';
  minLength: number;
  maxLength: number;
  value: string | null;
  customStyles?: Array<string>;
}

export interface OOStringControlModelInterface extends OOControlModelInterface {
  type: 'string';
  minLength: number;
  maxLength: number;
  value: string | null;
}

export interface OODateControlModelInterface extends OOControlModelInterface {
  type: 'date';
  minLength: Date;
  maxLength: Date;
  value: string | null;
  defaultValue?: string | undefined;
  onChangeCallback?: (event: any) => void;
}
export interface OOSelectorCheckboxModelInterface extends OOControlModelInterface {
  type: 'selector';
  controlItemName: string;
  value: boolean | undefined;
  defaultValue?: string | undefined;
  onChangeCallback?: (event: any) => void;
  iconType?: 'INFO' | 'HELP' | undefined;
}
export interface OOSelectorDropdownModelInterface extends OOControlModelInterface {
  type: 'selector';
  controlItemName: string;
  controlHeaderId: string;
  controlHeaderName: string;
  controlHeaderClass: string;
  documentId: string;
  value: string | null;
  parameters?: Array<Record<string, any>>;
  onChangeCallback?: (event: any) => void;
  defaultValue?: string | undefined;
  iconType?: 'INFO' | 'HELP' | undefined;
  hasHtmlDescription?: boolean;
  multiple?: boolean;
  checkBoxItems?: boolean;
  preventPopupWithoutInput?: boolean;
  dropdownOptions?: ControlItems;
  sortBySequence?: boolean;
}
export interface OOMultiSelectModelInterface extends OOControlModelInterface {
  type: 'selector';
  controlItemName: string;
  controlHeaderId: string;
  controlHeaderName: string;
  value: string | null;
  defaultValue: string | undefined;
  onChangeCallback?: (event: any) => void;
  itemModificationFunctions?: Map<string, (items: ControlItems) => ControlItems>;
}
export interface OOStepInFlow {
  name: string;
  sequence: number;
  isMandatory: boolean;
  role: string;
  feature: string;
  description: string;
  data: { [key: string]: any };
  pages: { [key: string]: any }[];
  documentations: { [key: string]: any }[];
  importFields: { officialName: string; actualName: string }[];
  importControls: any[];
}

export interface OOConfiguration {
  tenantId?: number;
  clientOrganizationId?: string;
  id: string;
  version?: number;
  isActive?: boolean;
  hasRecruiterSignature?: boolean;
  description?: string;
  flow?: OOStepInFlow[];
  createdAt?: Date;
  updatedAt?: Date;
  tiles?: Array<Record<string, any>>;
  stepFilters?: Array<Record<string, any>>;
  statusFilters?: Array<Record<string, any>>;
  customFilters?: Array<Record<string, any>>;
  exports?: Array<any>;
  clientOrganization?: { [key: string]: any };
  recruiterName: string;
  otherConfiguration: any[];
  selectors: { [key: string]: any[] };
  dashboardFiltersVisibility: { name: string; visible: boolean }[];
  allowedBulkActions?: { name: string; [key: string]: any }[];
  deletionReasonFilters?: Array<Record<string, any>>;
  recruiterSidebar?: RecruiterSidebarItem[];
  additionalConfigurations?: AdditionalConfigurations;
}

export interface Element {
  id: string;
  requiredId?: string;
  parentId?: string;
  parent?: Element;
  category: string;
  name: string;
  displayName?: string;
  isMandatory: boolean;
  sequence: number;
  version: number;
  isActive?: boolean;
  description?: string;
  createdAt?: Date;
  updatedAt?: Date;
  additionalData?: Record<string, any>;
}

export interface OOSelectedElement {
  tenantId: number;
  clientOrganizationId: string;
  configurationId: string;
  selectedConfiguration?: OOConfiguration;
  elementId: string;
  element?: Element;
  sequence: number;
  createdAt?: Date;
  updatedAt?: Date;
  stepId: string | null;
}

export type DropDownOptions = {
  [key: string]: Array<OOSelectorDropdownModelInterface>;
};

export enum HourCalcType {
  hoursPerDay = 'hoursPerDay',
  hoursPerWeek = 'hoursPerWeek',
  hoursPerMonth = 'hoursPerMonth',
}

export interface ICalculationClassUI {
  control: OOControlModelInterface;
  relevantControls: OOControlModelInterface[];
  onChange: (event: any) => void;
  init?: (value?: any) => void;
}

export enum StepStatusEnum {
  completed = 'COMPLETED',
  inProgress = 'IN_PROGRESS',
  notStarted = 'NOT_STARTED',
}
export interface SelectedLanguage {
  tenantId: number;
  clientOrganizationId: string;
  configurationId: string;
  code: string;
  name: string;
  isDefault: boolean;
  sequence: number;
  createdAt: Date;
  updatedAt: Date;
}
export interface OODashboardData {
  languages: Array<SelectedLanguage>;
  configurations: Array<OOConfiguration>;
  applications: Array<Onboarding>;
  rejectReasons: Array<string>;
}

export enum Component {
  ContentSwitcher = 'ContentSwitcher',
  Image = 'Image',
  Html = 'Html',
  Notification = 'Notification',
  StaticText = 'StaticText',
  Heading = 'Heading',
  ContainerWithSubcontrols = 'ContainerWithSubcontrols',
  Radio = 'Radio',
  RadioGroup = 'RadioGroup',
  RadioWithHelp = 'RadioWithHelp',
  Select = 'Select',
  SelectWithInfo = 'SelectWithInfo',
  SelectWithHelp = 'SelectWithHelp',
  SelectWithInfoHtml = 'SelectWithInfoHtml',
  SelectWithHelpHtml = 'SelectWithHelpHtml',
  MultipleSelect = 'MultipleSelect',
  SelectWithCheckbox = 'SelectWithCheckbox',
  MultiSelectWithCheckbox = 'MultiSelectWithCheckbox',
  CheckboxList = 'CheckboxList',
  Consent = 'Consent',
  Checkbox = 'Checkbox',
  CheckboxMultiSelect = 'CheckboxMultiSelect',
  Autocomplete = 'Autocomplete',
  Textfield = 'Textfield',
  WithInfoButton = 'WithInfoButton',
  MultilineTextfield = 'MultilineTextfield',
  TransformTextToUppercase = 'TransformTextToUppercase',
  Accordion = 'Accordion',
  AccordionWithAddButton = 'AccordionWithAddButton',
  AccordionWithAddButtonOpenModal = 'AccordionWithAddButtonOpenModal',
  AccordionWithSubcontrols = 'AccordionWithSubcontrols',
  ConsentWithoutCheckmark = 'ConsentWithoutCheckmark',
  Datepicker = 'Datepicker',
  Switch = 'Switch',
  SwitchWithInfo = 'SwitchWithInfo',
  Ranking = 'Ranking',
  DismissButton = 'DismissButton',
  SubmitButton = 'SubmitButton',
  SubmitLink = 'SubmitLink',
  AutocompletePreventDefaultOptions = 'AutocompletePreventDefaultOptions',
  WithHelpButton = 'WithHelpButton',
  ExtendableAutocomplete = 'ExtendableAutocomplete',
  RadioExpandOnSelect = 'RadioExpandOnSelect',
  TextfieldWithHtml = 'TextfieldWithHtml',
  SelectWithSequence = 'SelectWithSequence',
  RadioMultipleValues = 'RadioMultipleValues',
}
