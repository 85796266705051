import high from '../assets/highSeverity.svg';
import low from '../assets/lowSeverity.svg';
import moderate from '../assets/moderateSeverity.svg';

export const getSeverityDetails = (score: number) => {
  if (score > 0 && score <= 17) {
    return { label: 'Low', image: low, alt: 'lowSeverity' };
  } else if (score > 17 && score <= 40) {
    return { label: 'Moderate', image: moderate, alt: 'moderateSeverity' };
  } else {
    return { label: 'High', image: high, alt: 'highSeverity' };
  }
};
