import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';

import styles from './AuthLayout.module.scss';

interface Props {
  children: React.ReactChild[] | React.ReactChild;
}

const AuthLayout: React.FC<Props> = ({ children }) => {
  const tenant = useSelector((state: RootState) => state.tenant);

  const imageStaticUrl = '/images/branding/';
  const isDisAG = tenant?.tenantId === 131;
  const tenantLogo = `${imageStaticUrl}${isDisAG ? 'dis-ag-logo-group.svg' : 'adecco-logo-white.svg'}`;
  const altText = isDisAG ? 'DIS AG logo' : 'Adecco logo';

  const renderDisAGLayout = () => (
    <div className={styles.container}>
      <div className={clsx(styles.content, 'tag-ds')}>{children}</div>
      <img src={tenantLogo} alt={altText} loading="lazy" />
    </div>
  );

  const renderDefaultLayout = () => (
    <div className={styles.container}>
      <div className={clsx(styles.content, 'tag-ds')}>{children}</div>
      <div className={styles.branding}>
        <div className={styles.logo}>
          <img src={tenantLogo} alt={altText} loading="lazy" />
        </div>
        <div className={styles.drawing} />
      </div>
    </div>
  );

  return isDisAG ? renderDisAGLayout() : renderDefaultLayout();
};

export default AuthLayout;
